import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import {Popup} from '@partssourceinc/react-ui-core';
import {getPartCondition} from 'productUtility';
import AddressDialog, {AddressFormType} from 'components/AddressDialog';
import CartSummary from 'components/CartSummary';
import CybersourceDialog from 'components/CybersourceDialog';
import PrintQuote from 'components/PrintQuote'
import PageMetaData from 'components/PageMetaData';
import PurchasePanel from 'components/PurchasePanel';
import ShippingAttnPreview from 'components/ShippingAttnPreview';
import ShippingLabelPreview from 'components/ShippingLabelPreview'
import * as CartStore from 'stores/Cart';
import * as NetworkStore from 'stores/Network';
import * as UserStore from 'stores/User';
import {formatMoney, getFormattedPhoneNumber, uuidv4, slugify} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import {TextField, Checkbox, Button, Dropdown, RadioButtonList, RadioButton} from '@partssourceinc/react-ui-core';
import {getFormularyDisplayType} from 'productUtility';
import 'less/checkout.less';
import _ from 'lodash';
import ShippingStep from './components/ShippingStep';

@withRouter
@connect((state) => ({checkout: state.cart.checkout, network: state.network, user: state.user}), CartStore.actionCreators)
export default class Checkout extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        ...CartStore.ActionShape,
        checkout: CartStore.CheckoutShape,
        network: NetworkStore.StateShape,
        user: UserStore.StateShape,
    };

    static displayName = 'Checkout';

    shippingMethod = React.createRef();
    paymentMethod = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            MaxShippingAttnLength: 35,
            ShowCartDetails: false,
            CalculateShipping: true,
            defaultAddressChange: false,
            ShippingLocationView: {
                Open: true,
                Complete: false,
                ShowErrors: false,

            },
            ShippingMethodView: {
                Open: false,
                Complete: false,
                ShowErrors: false,
                ShowAccountNoEdit: false,
            },
            PaymentInformationView: {
                Open: false,
                Complete: false,
                ShowErrors: false,
            },
            RetrievingShippingOptions: false,
            CalculatingTaxes: false,
            SubmittingOrder: false,
            AlternateShipOptionSelected: false,
            ShowPsShipOnlyItemMsg: false,
            HasPsShipOnlyItem: false,
            ShowCCDialog: false,
            CybersourceRequest: {},
            ShowAddressDialog: false,
            AddressEdit: {},
            AddressFormDisplay: AddressFormType.AddEdit,
            ProceedAfterValidation: false,
            ShowCybersourceError: false,
            RequiresApproval: false,
            TempShipAccountNo: '',
            poRequired: false,
            showCheckoutError: false,
            showAlreadyCheckedoutError: false,
            ReadOnly: false,
            ApprovalRequiresPO: false,
            checkoutErrorMessage: undefined,
            validLineItems: [],
            productInformation: [],
            token: '',
            widgetId: '',
        };

        this.formRef = React.createRef();
    }

    componentDidMount() {
        const {saveCheckout, checkout, user: {info: {rootCompanyId}, turnstileCompanies}} = this.props;
        this.checkoutInitialSetup();

        window.addEventListener('message', this.handleCyberSourceResponse);
        
        if (window.turnstile && turnstileCompanies?.includes(rootCompanyId)) {
            const widgetId = window.turnstile.render('#turnstile', {
                sitekey: process.env.REACT_APP_TURNSTILE_KEY,
                callback: (token) => {
                    this.setState({token});
                },
            });
            this.setState({widgetId});
        }

        try {
            const sigOrderSessionId = uuidv4();
            let scriptTag = document.createElement('script');
            scriptTag.setAttribute('defer', 'defer');
            scriptTag.setAttribute('data-order-session-id', sigOrderSessionId);
            scriptTag.setAttribute('id', 'sig-api');
            scriptTag.setAttribute('src', 'https://cdn-scripts.signifyd.com/api/script-tag.js');
            document.body.appendChild(scriptTag);
            saveCheckout({...checkout, ...{sigOrderSessionId}});
        } catch {
            console.log('Failed to load signifyd script');
        }
    }

    componentWillUnmount() {
        const {checkout} = this.props;
        if (checkout.promoCode) {
            let lineItemIds = checkout.items.map(x => x.lineItemId);
            axios.post('/ShoppingService/api/v1/cart/remove/promo', {lineItemIds});
        }

        window.removeEventListener('message', this.handleCyberSourceResponse);

        try {
            let scriptTag = document.getElementById('sig-api');
            document.body.removeChild(scriptTag);
        } catch {
            console.timeLog('Failed to remove signifyd script');
        }
    }

    checkoutInitialSetup = () => {
        const {history, network, checkout: {items, canCheckout, isApprovalOrder, facilitySettings: {purchaseLimit, isLawson, bypassFormularyApproval, holdForPo, submitForPo}, readOnlyShipping, readOnlyPaymentInformation, paymentInformation}, getOrderTotals, user: {settings}} = this.props;
        if (!canCheckout) {
            history.push('/cart');
            return;
        }

        let cartProducts = items.filter(i => i.partNumber);
        const productRequest = cartProducts.filter(p => p.partNumber).map(p => {
            return {partNumber: slugify(p.partNumber), selectedFacilityId: p.facilityId, customerId: p.facilityId, requestorId: p.contactId};
        });
        this.sendProductInformationRequest(productRequest);

        const hasAllValidContractItems = items.every(x => x.isContractProOption && x.isValidContractPoNumber);
        const readOnly = readOnlyShipping || readOnlyPaymentInformation;
        const approvalRequiresPO = settings.approvalRequiresPO && !holdForPo && !submitForPo;
        this.setState({ReadOnly: readOnly, ApprovalRequiresPO: approvalRequiresPO});

        const hasMissingContractPo = items.some(x => x.isContractProOption && !x.isValidContractPoNumber);
        const hasMissingPartPo = items.some(x => !x.poNumber && !x.isContractProOption) && !paymentInformation.poNumber;
        let hasMissingPo = hasMissingContractPo || hasMissingPartPo;

        if (isApprovalOrder || readOnly) {
            let {ShippingLocationView, ShippingMethodView, PaymentInformationView} = this.state;
            ShippingLocationView.Open = false;
            ShippingLocationView.Complete = true;
            ShippingMethodView.Complete = true;
            PaymentInformationView.Open = !isLawson && !hasAllValidContractItems && !readOnlyPaymentInformation && hasMissingPo;
            PaymentInformationView.Complete = isLawson || hasAllValidContractItems || readOnlyPaymentInformation || !hasMissingPo;

            if (PaymentInformationView.Complete) {
                this.setState({CalculatingTaxes: true});
                getOrderTotals(true).then(x => {
                    if (x.type === 'GET_TOTALS_ERR') {
                        this.setState({showCheckoutError: true, CalculatingTaxes: false});
                        return;
                    }
                    this.setState({CalculatingTaxes: false});
                });
            }
        }

        const subTot = (items || []).filter(x => x.quantity).reduce(function (res, item) {
            return res + (item.price * item.quantity);
        }, 0);

        const itemRequiresApproval = items.some(x => !x.isFormularyOption && x.nonFormularyApprovalRequired && ((x.price * x.quantity) > x.nonFormularyApprovalLimit)) && purchaseLimit !== 999999; 
        this.setState({RequiresApproval: subTot > purchaseLimit || (isLawson && network.tokenInfo.subscriptionId === false) || (!isApprovalOrder && !bypassFormularyApproval && itemRequiresApproval)});
        this.setState({showAlreadyCheckedoutError: false});
    }

    submitAdobeCheckoutStarted() {
        const {checkout} = this.props;
        const OrderId = (checkout.items && checkout.items.length) ? Math.min(...checkout.items.map(item => item.orderId)) : 0;

        window.appEventData = window.appEventData || [];
        window.appEventData.push({
            'event': 'Checkout Started',
            'cart': {
                'cartID': OrderId.toString(),
                'item': checkout.items.map(item => ({
                    'price': {
                        'basePrice': item.listPrice.toFixed(2).toString(),
                        'priceTier': '', 
                        'priceType': getPartCondition(item.conditionId),
                        'sellingPrice': item.price.toFixed(2).toString(),
                    },
                    'productInfo': {
                        'facilityID': item.facilityId.toString(),
                        'brand': item.oemName,
                        'businessUnit': item.modality || '',
                        'criticalHardDown': item.urgencyId === 1 ? 'True' : 'False',
                        'formularyName': item.formularyRuleName || '',
                        'formularyDisplayType': getFormularyDisplayType(item.suppressionBehavior, item.formularyId),
                        'name': item.oemItemNumber,
                        'productCategory': item.category ? item.category : item.categories?.map(category => category.split('|').pop()).join(', ') || '',
                        'productCondition': getPartCondition(item.conditionId),
                        'productID': item.oemItemNumber,
                        'productLine': '',
                        'productPurchaseType': item.purchaseChoice,
                        'productVariant': Object.keys(item.differentiatingAttributes).length !== 0 ? Object.entries(item.differentiatingAttributes).map(([key, value]) => `${key}~${value}`).join('|') : '',
                        'refId': item.lineItemId.toString(),
                        'sku': item.vString,
                        'vString': item.vString,
                    },
                    'quantity': item.quantity,
                })), 
            },
        });
        this.submitAdobeCheckoutStepProgress('Checkout Shipping Location Started');
    }
    
    sendProductInformationRequest = (productRequest) => {
        let products = [];
        axios.post(`/CatalogService/api/v1/products/GetCatalogItems`, productRequest).then(response => {
            let productData = _.orderBy(response.data, ['index']);
            for (let i = 0; i < productData.length; i++) {
                products.push(productData[i].value.product);
            }
            this.setState({productInformation: products});
            this.submitAdobeCheckoutStarted(products);
        });
    }

    handleCyberSourceResponse = (response) => {
        let currentLocation = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        if (currentLocation !== response.origin || (!response.data.transactionId && !response.data.showMessage)) {
            return;
        }

        if (!response.data.transactionId && response.data.showMessage) {
            this.setState({ShowCybersourceError: true});
            return;
        }

        let {submitOrder, checkout, saveCheckout, loadUserCart, history} = this.props;
        checkout.cybersourceResponse = response.data;
        saveCheckout(checkout);

        this.setState({ShowCCDialog: false});
        submitOrder().then(x => {
            if (x.type === 'SUBMIT_ORDER_ERR') {
                this.setState({showCheckoutError: true, SubmittingOrder: false, checkoutErrorMessage: x.response.response.data.message});
                return;
            }
            loadUserCart();
            sessionStorage.removeItem('fields');
            sessionStorage.removeItem('wo_id');
            this.submitAdobeAnalyticsForCheckout();
            history.push('/checkout/confirmation');
        });
    }

    handleShipAttentionChange = (event) => {
        let {checkout, saveCheckout} = this.props;
        checkout.shippingLocation.attentionTo = event.target.value.trim();
        saveCheckout(checkout);
    }

    handleIncludePoChange = (event) => {
        let {checkout, saveCheckout} = this.props;
        checkout.shippingLocation.includePo = event.checked;
        saveCheckout(checkout);
    }

    handlePoNumberChange = (event) => {
        let {checkout, saveCheckout} = this.props;
        let {PaymentInformationView} = this.state;
        let poNumberValue = event.target.value.trim();

        if (this.validatePONumber(poNumberValue)) {
            checkout.paymentInformation.poNumber = poNumberValue;
            saveCheckout(checkout);
        } else {
            PaymentInformationView.ShowErrors = true;
            this.setState({PaymentInformationView});
            checkout.paymentInformation.poNumber = poNumberValue;
        }
    }

    handleBypassPOChange = (event) => {
        let {checkout, saveCheckout} = this.props;
        checkout.paymentInformation.bypassPo = event.checked;
        saveCheckout(checkout);
    }

    handlePaymentMethodChange = (e, data) => {
        let {checkout, saveCheckout} = this.props;
        checkout.paymentInformation.paymentMethod = checkout.paymentMethods.filter(x => x.value === data.value)[0] || {};
        saveCheckout(checkout);
    }

    handleShippingAddressChange = (event, data) => {
        const shippingAddress = data.options.find(x => x.addressId === Number(event.target.value)) || {};
        const {checkout, saveCheckout} = this.props;

        saveCheckout(Object.assign({}, checkout, {shippingAddress}));
        this.setState({CalculateShipping: true, defaultAddressChange: true});
    }

    handleBillingAddressChange = (event, data) => {
        const billingAddress = data.options.find(x => x.addressId === Number(event.target.value)) || {};
        const {checkout, saveCheckout} = this.props;

        saveCheckout(Object.assign({}, checkout, {billingAddress}));
    }

    openSection = (section) => {
        let {ShippingLocationView, ShippingMethodView, PaymentInformationView} = this.state;
        const {checkout: {shipments, isApprovalOrder}, getGroupedShippingOptions} = this.props;

        ShippingLocationView.Open = false;
        ShippingMethodView.Open = false;
        PaymentInformationView.Open = false;

        switch (section) {
            case 'ShippingLocation':
                ShippingLocationView.Open = true;
                this.submitAdobeCheckoutStepProgress('Checkout Shipping Location Started');
                break;
            case 'ShippingMethod':
                ShippingMethodView.Open = true;                
                if (shipments.length > 0 && shipments[0].shipOptions.length === 0) {
                    this.setState({RetrievingShippingOptions: true});
                    getGroupedShippingOptions().then(() => {
                        ShippingLocationView.Open = false;
                        ShippingLocationView.Complete = true;
                        ShippingMethodView.Complete = false;
                        ShippingMethodView.Open = true;
                        
                        if (!isApprovalOrder) {
                            let {checkout, saveCheckout} = this.props;
                            // restore defaults
                            const useCustomerAccount = checkout.shippingMethod.useCustomerAccount;                        
                            shipments.forEach(s => {
                                s.shippingMethod.useCustomerAccount = useCustomerAccount;
                                s.shippingMethod.shippingInsurance = checkout.shippingMethod.shippingInsurance;
                                s.shippingMethod.isGsa = checkout.shippingMethod.isGsa;
                            });                    

                            saveCheckout(checkout);
                        }
                                                
                        this.setState({ShippingLocationView, ShippingMethodView, RetrievingShippingOptions: false, CalculateShipping: false});
                    });                  
                }
                break;
            case 'PaymentInformation':
                PaymentInformationView.Open = true;
                break;
        }

        this.setState({ShippingLocationView, ShippingMethodView, PaymentInformationView});
    }

    closeSection = (section) => {
        let {getGroupedShippingOptions, getOrderTotals, user: {settings}, checkout, saveCheckout} = this.props;
        let {ShippingLocationView, ShippingMethodView, PaymentInformationView, CalculateShipping, RequiresApproval, defaultAddressChange, ApprovalRequiresPO} = this.state;
        let isDesktop = $(document).width() > 650;
        let checkoutItems = {};
        const {checkout: {items,shippingAddress, billingAddress, paymentInformation, shippingMethod, shippingLocation, facilitySettings: {holdForPo, submitForPo, isLawson}}} = this.props;
        const allContractItems = items.filter(x => !x.isContractProOption).length === 0;
        const isServiceCheckout = items.some(x => x.isServiceItem);
        const hasMissingPo = items.some(x => x.isContractProOption && !x.isValidContractPoNumber);
        const poRequired = ApprovalRequiresPO && hasMissingPo;
        let skipPaymentMethod = RequiresApproval && !poRequired;
       
        switch (section) {
            case 'ShippingLocation':
                if (!shippingAddress || !shippingAddress.addressId || !shippingLocation.attentionTo) {
                    ShippingLocationView.ShowErrors = true;
                    this.setState({ShippingLocationView});
                    return;
                }

                if (!shippingAddress.isValidated) {
                    this.setState({
                        ShowAddressDialog: true,
                        AddressFormDisplay: AddressFormType.Validate,
                        AddressEdit: shippingAddress,
                        ProceedAfterValidation: true});
                    return;
                }

                if (allContractItems || isServiceCheckout) {
                    if ((hasMissingPo || isServiceCheckout) && !skipPaymentMethod) {
                        ShippingLocationView.Open = false;
                        ShippingLocationView.Complete = true;
                        ShippingMethodView.Complete = true;
                        ShippingMethodView.Open = false;
                        PaymentInformationView.Complete = false;
                        PaymentInformationView.Open = true;
                        this.setState({ShippingLocationView, ShippingMethodView, PaymentInformationView});
                    } else {
                        ShippingLocationView.Open = false;
                        ShippingLocationView.Complete = true;
                        ShippingMethodView.Complete = true;
                        ShippingMethodView.Open = false;
                        PaymentInformationView.Complete = true;
                        this.setState({ShippingLocationView, ShippingMethodView, PaymentInformationView});
                    }
                } else if (CalculateShipping) {
                    this.setState({RetrievingShippingOptions: true});
                    getGroupedShippingOptions().then(x => {                        
                        if (x.type === 'GET_SHIPPING_ERR') {
                            this.setState({showCheckoutError: true, RetrievingShippingOptions: false});
                            return;
                        }
                        ShippingLocationView.Open = false;
                        ShippingLocationView.Complete = true;
                        ShippingMethodView.Complete = false;
                        ShippingMethodView.Open = true;

                        this.setState({ShippingLocationView, ShippingMethodView, RetrievingShippingOptions: false, CalculateShipping: false});
                        if (isDesktop) {
                            const offSet = this.shippingMethod.current.offsetTop - 100;
                            $('html, body').animate({scrollTop: offSet}, 500, 'linear');
                        }
                    });
                } else {
                    ShippingLocationView.Open = false;
                    ShippingLocationView.Complete = true;
                    if (!ShippingMethodView.Complete || !PaymentInformationView.Complete) {
                        ShippingMethodView.Open = true;
                        if (checkout.shipments.length > 0) {
                            checkout.shipments.forEach(s => s.complete = false);
                            checkout.shipments[0].open = true;
                            saveCheckout(checkout);
                        }
                    }
                    this.setState({ShippingLocationView, ShippingMethodView});
                }
                checkoutItems = (items || []).map(x => {
                    return {
                        'name': x.partNumber,
                        'id': x.oemItemNumber,
                        'price': x.price,
                        'brand': x.oemName,
                        'category': x.category,
                        'variant': `${getPartCondition(x.conditionId)} -  ${x.purchaseChoice}`,
                        'quantity': x.quantity,
                    }
                });
                this.submitAdobeCheckoutStepProgress('Checkout Shipping Location Completed');
                this.submitAdobeCheckoutStepProgress('Checkout Shipping Method Started');
                dataLayer.push({
                    'event': 'checkout',
                    'ecommerce': {
                        'checkout': {
                            'actionField': {'step': 'Ship Address' , 'option': defaultAddressChange ? 'Default Not Used' : 'Default Used'},
                            'products': checkoutItems,
                        },
                    },

                });
                break;
            case 'ShippingMethod':
                if (RequiresApproval && !ApprovalRequiresPO) {
                    ShippingMethodView.Open = false;
                    ShippingMethodView.Complete = true;
                    this.setState({ShippingMethodView});
                    if (isDesktop)
                        $('html, body').animate({scrollTop: 0}, 500, 'linear');
                } else if (!PaymentInformationView.Complete) {
                    ShippingMethodView.Open = false;
                    ShippingMethodView.Complete = true;
                    PaymentInformationView.Open = true;
                    if (isDesktop) {
                        const offSet = this.paymentMethod.current.offsetTop - 100;
                        $('html, body').animate({scrollTop: offSet}, 500, 'linear');
                    }
                    this.setState({ShippingLocationView, ShippingMethodView, PaymentInformationView});
                } else {
                    this.setState({CalculatingTaxes: true});
                    getOrderTotals(true).then(x => {
                        if (x.type === 'GET_TOTALS_ERR') {
                            this.setState({showCheckoutError: true, CalculatingTaxes: false});
                            return;
                        }
                        ShippingMethodView.Open = false;
                        ShippingMethodView.Complete = true;
                        this.setState({CalculatingTaxes: false, ShippingMethodView});
                    })
                }

                checkoutItems = (items || []).map(x => {
                    return {
                        'name': x.partNumber,
                        'id': x.oemItemNumber,
                        'price': x.price,
                        'brand': x.oemName,
                        'category': x.category,
                        'variant': `${getPartCondition(x.conditionId)} -  ${x.purchaseChoice}`,
                        'quantity': x.quantity,
                    }
                });
                this.submitAdobeCheckoutStepProgress('Checkout Shipping Method Completed');
                if (!skipPaymentMethod) {
                    this.submitAdobeCheckoutStepProgress('Checkout Payment Step Started');
                } else {
                    this.submitAdobeOrderReviewDisplayed();
                }
                dataLayer.push({
                    'event': 'checkout',
                    'ecommerce': {
                        'checkout': {
                            'actionField': {'step': 'Ship Method', 'option': shippingMethod.useCustomerAccount ? 'Customer Account' : 'PS ACCOUNT'},
                            'products': checkoutItems,
                        },
                    },
                });
                break;
            case 'PaymentInformation': {
                const hasNonContractItems = items.some(x => !x.isContractProOption);
                
                const poRequired = hasNonContractItems && !holdForPo && !submitForPo && (paymentInformation.paymentMethod.value === 'PO' || settings.poRequiredCC);
                
                if (!isLawson)
                    this.setState({poRequired});
                
                const invalidContractPoNumbers = items.some(x => x.isContractProOption && x.contractPoNumber == '') ? true : false;
                const invalidPONumber = !this.validatePONumber(paymentInformation.poNumber) && !paymentInformation.bypassPo;
                const invalidBillingAddress = hasNonContractItems ? (!billingAddress || !billingAddress.addressId) : false;
                
                if ((!isLawson && poRequired && !paymentInformation.poNumber && !paymentInformation.bypassPo) || 
                    !paymentInformation.paymentMethod.value || 
                    invalidBillingAddress || 
                    invalidContractPoNumbers || 
                    invalidPONumber) {
                    PaymentInformationView.ShowErrors = true;
                    this.setState({PaymentInformationView});
                    return;
                }

                this.setState({CalculatingTaxes: true});
                getOrderTotals(true).then(x => {
                    if (x.type === 'GET_TOTALS_ERR') {
                        this.setState({showCheckoutError: true, CalculatingTaxes: false});
                        return;
                    }
                    PaymentInformationView.Open = false;
                    PaymentInformationView.Complete = true;
                    this.setState({PaymentInformationView, CalculatingTaxes: false});
                    if (isDesktop)
                        $('html, body').animate({scrollTop: 0}, 500, 'linear');
                });
                this.setState({ShippingLocationView, ShippingMethodView, PaymentInformationView});
                checkoutItems = (items || []).map(x => {
                    return {
                        'name': x.partNumber,
                        'id': x.oemItemNumber,
                        'price': x.price,
                        'brand': x.oemName,
                        'category': x.category,
                        'variant': `${getPartCondition(x.conditionId)} -  ${x.purchaseChoice}`,
                        'quantity': x.quantity,
                    }
                });
                this.submitAdobeCheckoutStepProgress('Checkout Payment Step Completed');
                this.submitAdobeOrderReviewDisplayed();
                dataLayer.push({
                    'event': 'checkout',
                    'ecommerce': {
                        'checkout': {
                            'actionField': {'step': ' Payment', 'option': paymentInformation.paymentMethod.value === 'PO' ? 'Purchase Order' : 'Credit Card'},
                            'products': checkoutItems,
                        },
                    },
                });
                break;
            }
        }
    }

    toggleCartDetails = () => {
        const {ShowCartDetails} = this.state;
        this.setState({ShowCartDetails: !ShowCartDetails});
    }

    submitAdobeCheckoutStepProgress(stepProgress) {
        const {checkout} = this.props;
        const {productInformation} = this.state;
        const products = productInformation;
        const OrderId = (checkout.items && checkout.items.length) ? Math.min(...checkout.items.map(item => item.orderId)) : 0;

        window.appEventData = window.appEventData || [];
        let stepProgressEvent = {
            'event': stepProgress,
            'cart': {
                'cartID': OrderId.toString(),
                'item': checkout.items.map(item => ({
                    'price': {
                        'basePrice': item.listPrice.toFixed(2).toString(),
                        'priceTier': '', 
                        'priceType': getPartCondition(item.conditionId),
                        'sellingPrice': item.price.toFixed(2).toString(),
                    },
                    'productInfo': {
                        'facilityID': item.facilityId.toString(),
                        'brand': item.oemName,
                        'businessUnit': products.find(p => p.oemItemNumber === item.oemItemNumber)?.modality || '',
                        'criticalHardDown': item.urgencyId === 1 ? 'True' : 'False',
                        'formularyName': item.formularyRuleName || '',
                        'formularyDisplayType': getFormularyDisplayType(item.suppressionBehavior, item.formularyId),
                        'name': item.oemItemNumber,
                        'productCategory': item.category ? item.category : item.categories?.map(category => category.split('|').pop()).join(', ') || '',
                        'productCondition': getPartCondition(item.conditionId),
                        'productID': item.oemItemNumber,
                        'productLine': '',
                        'productPurchaseType': item.purchaseChoice,
                        'productVariant': Object.keys(item.differentiatingAttributes).length !== 0 ? Object.entries(item.differentiatingAttributes).map(([key, value]) => `${key}~${value}`).join('|') : '',
                        'refId': item.lineItemId.toString(),
                        'sku': item.vString || '',
                        'vString': item.vString || '',
                    },
                    'quantity': item.quantity,
                })), 
            }};
        if (stepProgress === 'Checkout Payment Step Completed') {
            stepProgressEvent.cart.payment = [
                {
                    'paymentMethod': checkout.paymentInformation.paymentMethod.value,
                },
            ];
        }
        if (stepProgress === 'Checkout Shipping Method Completed') {
            stepProgressEvent.cart.cartShippingCost = checkout.shipments.reduce((total, shipment) => total + shipment.selectedShipOption.netShippingCharge, 0).toFixed(2).toString();
            stepProgressEvent.cart.item.forEach((item) => {
                item.shipping = {
                    'shippingCarrier': checkout.shipments.find(x => x.lineItemId === item.lineItemId).shippingMethod.carrier.toString(),
                    'shippingInsurance': checkout.shipments.find(x => x.lineItemId === item.lineItemId).shippingMethod.shippingInsurance.toString(),
                    'shippingMethod': checkout.shipments.find(x => x.lineItemId === item.lineItemId).selectedShipOption.serviceTypeDescription.toString(),
                };
            });
        }
        window.appEventData.push(stepProgressEvent);
    }

    submitAdobeOrderReviewDisplayed() {
        const {checkout} = this.props;
        const {productInformation} = this.state;
        const products = productInformation;
        window.appEventData = window.appEventData || [];
        window.appEventData.push({
            'event': 'Checkout Order Review Displayed',
            'cart': {
                'cartID': Math.min(...checkout.items.map(item => item.orderId)).toString() || '0',
                'item': checkout.items.map(item => ({
                    'price': {
                        'basePrice': item.listPrice.toFixed(2).toString(),
                        'priceTier': '', 
                        'priceType': getPartCondition(item.conditionId),
                        'sellingPrice': item.price.toFixed(2).toString(),
                    },
                    'productInfo': {
                        'facilityID': item.facilityId.toString(),
                        'brand': item.oemName,
                        'businessUnit': products.find(p => p.oemItemNumber === item.oemItemNumber)?.modality || '',
                        'criticalHardDown': item.urgencyId === 1 ? 'True' : 'False',
                        'formularyName': item.formularyRuleName || '',
                        'formularyDisplayType': getFormularyDisplayType(item.suppressionBehavior, item.formularyId),
                        'name': item.oemItemNumber,
                        'productCategory': item.category,
                        'productID': item.oemItemNumber,
                        'productCondition': getPartCondition(item.conditionId),
                        'productPurchaseType': item.purchaseChoice,
                        'productLine': '',
                        'productVariant': Object.keys(item.differentiatingAttributes).length !== 0 ? Object.entries(item.differentiatingAttributes).map(([key, value]) => `${key}~${value}`).join('|') : '',
                        'refId': item.lineItemId.toString(),
                        'sku': item.vString,
                        'vString': item.vString,
                    },
                    'quantity': item.quantity,
                })),
            },
        });
    }

    submitOrder = () => {
        const {RequiresApproval, token, widgetId} = this.state;
        let checkoutItems = {};
        let {submitOrder, network, history, loadUserCart, generateCreatePaymentRequest, checkout: {paymentInformation, items, totals, promoCode, isApprovalOrder, facilitySettings: {isLawson}},
            user: {info: {rootCompanyId}, turnstileCompanies}} = this.props;
        const displayButtonText = this.getDisplayButtonText();
        this.setState({SubmittingOrder: true});

        if (paymentInformation.paymentMethod.value === 'PO' || RequiresApproval) {
            submitOrder().then(x => {
                if (x.type === 'SUBMIT_ORDER_ERR') {
                    this.setState({showCheckoutError: true, SubmittingOrder: false});
                    return;
                }
                if (!x.response.data?.success) {                                      
                    if (isApprovalOrder && x.response.data?.hasInvalidCartItems) {
                        this.setState({showAlreadyCheckedoutError: true, SubmittingOrder: false, validLineItems: x.response.data.cartItems.map(ci => ci.lineItemId)});
                        return;
                    } else {
                        this.setState({showCheckoutError: true, SubmittingOrder: false});
                        return;
                    }
                }
                sessionStorage.removeItem('fields');
                sessionStorage.removeItem('wo_id');
                loadUserCart();
                this.submitAdobeAnalyticsForCheckout();
                history.push('/checkout/confirmation');
            });
        } else {
            generateCreatePaymentRequest(token)
                .then(x => {
                    this.setState({CybersourceRequest: x.response.data.cybersourceRequest, ShowCCDialog: true});
                })
                .finally(_ => {
                    if (window.turnstile && turnstileCompanies?.includes(rootCompanyId)) {     
                        window.turnstile.reset(widgetId);
                    }
                });
        }

        checkoutItems = (items || []).map(x => {
            return {
                'name': x.partNumber,
                'id': x.oemItemNumber,
                'price': x.price,
                'brand': x.oemName,
                'category': 'n/a',
                'variant': `${getPartCondition(x.conditionId)} -  ${x.purchaseChoice}`,
                'quantity': x.quantity,
            }
        });
        dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': {
                        'step': 'Submit Order', 'option': displayButtonText,
                    },
                    'products': checkoutItems,
                },
            },

        });

        if ((!isLawson && !network.tokenInfo.subscriptionId) && displayButtonText === 'Purchase') {
            let sortedOrders = items.length > 0 ? _.orderBy(items, ['orderId'], ['asc']) : null;
            const OrderId = (sortedOrders && sortedOrders.length) ? sortedOrders[0].orderId : 0;
            dataLayer.push({
                'event': 'eec.purchase',
                'ecommerce': {
                    'purchase': {
                        'actionField': {
                            'id': OrderId,
                            'revenue': totals.subTotal + totals.shipping + totals.transitCoverages + totals.taxes,
                            'tax': totals.taxes,
                            'shipping': totals.shipping + totals.transitCoverages,
                            'coupon': promoCode,
                        },
                        'products': checkoutItems,
                    },
                },
            });
        }
    }

    submitAdobeAnalyticsForCheckout = () => {
        const {checkout} = this.props;
        const {productInformation} = this.state;
        const OrderId = (checkout.items && checkout.items.length) ? Math.min(...checkout.items.map(item => item.orderId)) : 0;
        const productMap = productInformation && productInformation.length > 0 ? productInformation.reduce((acc, product) => {
            acc[product?.oemItemNumber] = product;
            return acc;
        }, {}) : {};
        const displayButtonText = this.getDisplayButtonText();

        window.appEventData = window.appEventData || [];
        if (displayButtonText !== 'Purchase') {
            window.appEventData.push({
                'event': 'Nonterminal Order Placed',
                'cart': {
                    'cartID': OrderId.toString(),
                },
                'transaction': {
                    'item': checkout.items.map(item => ({
                        'price': {
                            'basePrice': item.listPrice.toFixed(2).toString(),
                            'priceTier': '', 
                            'priceType': getPartCondition(item.conditionId),
                            'sellingPrice': item.price.toFixed(2).toString(),
                            'totalPrice': (item.price * item.quantity).toFixed(2).toString(),
                        },
                        'productInfo': {
                            'facilityID': item.facilityId.toString(),
                            'brand': item.oemName,
                            'businessUnit': productMap[item.oemItemNumber]?.modality || '',
                            'formularyName': item.formularyRuleName || '',
                            'formularyDisplayType': getFormularyDisplayType(item.suppressionBehavior, item.formularyId),
                            'criticalHardDown': item.urgencyId === 1 ? 'True' : 'False',
                            'name': item.partNumber,
                            'productCategory': productMap[item.oemItemNumber]?.categories?.map(category => category.split('|').pop()).join(', ') || item.category || '',
                            'productID': item.oemItemNumber,
                            'productLine': '',
                            'productCondition': getPartCondition(item.conditionId),
                            'productVariant': Object.keys(item.differentiatingAttributes).length !== 0 ? Object.entries(item.differentiatingAttributes).map(([key, value]) => `${key}~${value}`).join('|') : '',
                            'productPurchaseType': item.purchaseChoice,
                            'refId': item.lineItemId.toString(),
                            'sku': item.vString,
                            'vString': item.vString,
                        },
                        'quantity': item.quantity,
                        'shippingMethod': checkout.shipments.map(x => x.selectedShipOption.serviceTypeDescription).join(', '),
                    })),
                    'orderAttempt': this.getOrderAttempts(),
                    'orderStatus': displayButtonText,
                    'profile': {
                        'address': {
                            'country': checkout.shippingAddress.country,
                            'postalCode': checkout.shippingAddress.postalCode,
                            'stateProvince': checkout.shippingAddress.state,
                        },
                    },
                    'purchaseID': OrderId.toString(),
                    'total': {
                        'currency': 'USD',
                    },
                    'transactionID': OrderId.toString(),
                    'orderID': OrderId.toString(),
                },
            });
        } else {
            window.appEventData.push({
                'event': 'Terminal Order Placed',
                'cart': {
                    'cartID': OrderId.toString(),
                },
                'transaction': {
                    'item': checkout.items.map(item => ({
                        'price': {
                            'basePrice': item.listPrice.toFixed(2).toString(),
                            'priceTier': '', 
                            'priceType': getPartCondition(item.conditionId),
                            'sellingPrice': item.price.toFixed(2).toString(),
                            'totalPrice': (item.price * item.quantity).toFixed(2).toString(),
                        },
                        'productInfo': {
                            'facilityID': item.facilityId.toString(),
                            'brand': item.oemName,
                            'businessUnit': productMap[item.oemItemNumber]?.modality || '',
                            'formularyName': item.formularyRuleName || '',
                            'formularyDisplayType': getFormularyDisplayType(item.suppressionBehavior, item.formularyId),
                            'criticalHardDown': item.urgencyId === 1 ? 'True' : 'False',
                            'name': item.partNumber,
                            'productCategory': productMap[item.oemItemNumber]?.categories?.map(category => category.split('|').pop()).join(', ') || item.category || '',
                            'productID': item.oemItemNumber,
                            'productLine': '',
                            'productCondition': getPartCondition(item.conditionId),
                            'productVariant': Object.keys(item.differentiatingAttributes).length !== 0 ? Object.entries(item.differentiatingAttributes).map(([key, value]) => `${key}~${value}`).join('|') : '',
                            'productPurchaseType': item.purchaseChoice,
                            'refId': item.lineItemId.toString(),
                            'sku': item.vString,
                            'vString': item.vString,
                        },
                        'quantity': item.quantity,
                        'shippingMethod': checkout.shipments.map(x => x.selectedShipOption.serviceTypeDescription).join(', '),
                        'tax': checkout.totals.taxes.toString(),
                        'voucherDiscount': {
                            'orderLevelDiscountAmount': '',
                            'orderLevelDiscountCode': checkout.promoCode,
                        },
                    })),
                    'orderAttempt': this.getOrderAttempts(),
                    'orderStatus': displayButtonText,
                    'orderShippingCost': (checkout.totals.shipping + checkout.totals.transitCoverages).toFixed(2).toString(),
                    'payment': [{
                        'paymentAmount': (checkout.totals.subTotal + checkout.totals.shipping + checkout.totals.transitCoverages + checkout.totals.taxes).toFixed(2).toString(),
                        'paymentGateway': '', 
                        'paymentID': '', 
                        'paymentMethod': checkout.paymentInformation.paymentMethod.value.toString(),
                    }],
                    'profile': {
                        'address': {
                            'country': checkout.shippingAddress.country,
                            'postalCode': checkout.shippingAddress.postalCode,
                            'stateProvince': checkout.shippingAddress.state,
                        },
                    },
                    'purchaseID': `${OrderId.toString()}-${Math.min(...checkout.items.map(item => item.lineItemId))}`,
                    'total': {
                        'currency': 'USD',
                        'numPayments': 1,
                    },
                    'transactionID': OrderId.toString(),
                    'orderID': OrderId.toString(),
                },
            });
        }
    }
    
    closeCCDialog = () => {
        this.setState({ShowCCDialog: false, SubmittingOrder: false, ProceedAfterValidation: false, ShowCybersourceError: false});
    }

    getOrderAttempts = () => {
        const {checkout: {items}} = this.props;
        const itemWithLowestOrderId = items.reduce((lowest, current) => (current.orderId < lowest.orderId) ? current : lowest);
        const trackedOrderAttemps = itemWithLowestOrderId.orderAttempt === 0 ? 1 : itemWithLowestOrderId.orderAttempt + 1;
        return trackedOrderAttemps.toString();
    }

    getDisplayButtonText = () => {
        const {RequiresApproval} = this.state;
        let {network, checkout: {facilitySettings: {submitForPo, isLawson}}} = this.props;
        let displayButtonText;

        if (isLawson && network.tokenInfo.subscriptionId === false) displayButtonText = 'Punchout Purchase';
        if (submitForPo) displayButtonText = 'Submit for PO';
        if (RequiresApproval) displayButtonText = 'Submit for Approval';
        else displayButtonText = 'Purchase';
        
        return displayButtonText;
    }

    onAddressSave = (address, isNew) => {
        let {checkout, checkout: {facility}, saveCheckout, loadFacilityAddresses} = this.props;
        const {ProceedAfterValidation} = this.state;

        if (isNew) {
            loadFacilityAddresses(facility.facilityId).then(x => {
                checkout.shippingAddresses = x.response.data.shippingAddresses;
                checkout.shippingAddress = address;
                saveCheckout(checkout);
                this.setState({ShowAddressDialog: false});
            });
        } else {
            checkout.shippingAddress = address;
            saveCheckout(checkout);
            if (ProceedAfterValidation) {
                this.closeSection('ShippingLocation');
            }
            this.setState({ShowAddressDialog: false, ProceedAfterValidation: false});
        }
    }

    onEditShippingAccountNo = (event) => {
        event.preventDefault();
        let {ShippingMethodView} = this.state;
        const {checkout: {shippingMethod: {shipAccountNo}}} = this.props;
        ShippingMethodView.ShowAccountNoEdit = true;
        this.setState({ShippingMethodView, TempShipAccountNo: shipAccountNo || ''});

    }

    onShippingAcountNoChanged = () => {
        let {ShippingMethodView, TempShipAccountNo} = this.state;
        let {checkout, saveCheckout} = this.props;
        checkout.shippingMethod.shipAccountNo = TempShipAccountNo;
        saveCheckout(checkout);
        ShippingMethodView.ShowAccountNoEdit = false;
        this.setState({ShippingMethodView, TempShipAccountNo: ''});
    }

    handleSaveCcChange = (event) => {
        let {checkout, saveCheckout} = this.props;
        checkout.paymentInformation.saveCC = event.checked;
        saveCheckout(checkout);
    }

    handleContractPoChange = (e, items) => {
        items.forEach(i => i.contractPoNumber = e.target.value);
    }

    onShippingMethodViewChange = (view) => {
        if (view.Open) {                        
            this.openSection('ShippingMethod');            
        } else {
            this.closeSection('ShippingMethod');
        }
        
    }

    renderPayment = (items, i) => {
        const {PaymentInformationView} = this.state;
        const allowEdit = items.some(x => !x.isValidContractPoNumber);
        const showPoSummary = PaymentInformationView.Open && allowEdit ? false : true;

        return (
            <div className="contract-payment">
                <div className="payment-amount">
                    <span className="lbl">Payment {i + 1}</span>
                    <span className="val">- {formatMoney(_.sumBy(items, 'price'))}</span>
                </div>
                <div className="contract-badge"><span>CONTRACT</span></div>
                <div className="details">
                    <span className="lbl">Contract #:</span>
                    <span className="val contract">{items[0].contractProName}</span>
                    {showPoSummary ?
                        <React.Fragment>
                            <span className="lbl">PO #:</span>
                            <span className="val">{items[0].contractPoNumber}</span>
                        </React.Fragment>
                        :
                        <span className="po-number">
                            <TextField id={items[0].contractId} label="P.O.#"
                                showErrorMessage={!items[0].contractPoNumber && PaymentInformationView.ShowErrors}
                                placeholder="P.O.#" text={items[0].contractPoNumber}
                                onChange={(e) => this.handleContractPoChange(e, items)} />
                        </span>}
                </div>
            </div>
        )
    }

    validatePONumber = (poNumber) => poNumber ? poNumber.match(/^[A-Za-z0-9\-\s]{1,61}$/) : (this.state.poRequired ? false : true);

    alreadyCheckedOutDialogClose = () => {
        const {validLineItems: lineItemIds} = this.state;
        const {history, proceedToCheckout} = this.props;

        this.setState({showAlreadyCheckedoutError: false, showCheckoutError: false});
        if (lineItemIds && lineItemIds.length > 0) {
            proceedToCheckout(lineItemIds).then(proceedResult => {
                this.setState({validLineItems: []})
                if (proceedResult.type === 'PROCEED_TO_CHECKOUT_RESP') {
                    this.checkoutInitialSetup();
                } else {
                    this.setState({showCheckoutError: true});
                    return;
                }
            });
        } else {
            history.push('/approvals');
        }
    }

    render() {
        const {
            ShippingLocationView,
            ShippingMethodView,
            PaymentInformationView,
            MaxShippingAttnLength,
            ShowCartDetails,
            RetrievingShippingOptions,
            CalculatingTaxes,
            SubmittingOrder,
            AlternateShipOptionSelected,
            ShowPsShipOnlyItemMsg,
            HasPsShipOnlyItem,
            ShowCCDialog,
            CybersourceRequest,
            ShowAddressDialog,
            AddressEdit,
            AddressFormDisplay,
            ShowCybersourceError,
            RequiresApproval,
            showCheckoutError,
            showAlreadyCheckedoutError,
            ReadOnly,
            ApprovalRequiresPO,
        } = this.state;

        const {checkout: {
            billingAddress,
            shippingAddress,
            shippingLocation,            
            paymentInformation,
            paymentInformation: {poNumber},
            items,
            facility,
            shippingAddresses,
            billingAddresses,
            paymentMethods: payMethods,            
            facilitySettings,            
            readOnlyPaymentInformation},
        user: {settings, info: {rootCompanyId}, turnstileCompanies},
        location} = this.props;

        const hideForApprovals = location?.state?.hideForApprovals ?? null;
        const paymentMethods = ApprovalRequiresPO && RequiresApproval ? 
            payMethods.filter(x => x.value === 'PO') : payMethods;
        
        const ShippingAttentionDisplay = shippingLocation.attentionTo ?
            (shippingLocation.includePo ? 'PO#:' + poNumber + ', ' + shippingLocation.attentionTo : shippingLocation.attentionTo).substring(0, 35) :
            (shippingLocation.includePo ? 'PO#:' + poNumber : '').substring(0, 35);

        const allowPurchase = !ShippingLocationView.Open && !ShippingMethodView.Open && !PaymentInformationView.Open
            && ShippingLocationView.Complete && ShippingMethodView.Complete && ((PaymentInformationView.Complete && !CalculatingTaxes) || RequiresApproval);
                    
        const hasContractItems = items.some(x => x.isContractProOption);
        const hasNonContractItems = items.some(x => !x.isContractProOption);
        const groupedContractItems = _.groupBy(items.filter(x => x.isContractProOption), x => x.contractProId);
        const allowContractPoEdit = items.some(x => !x.isValidContractPoNumber);

        const dedicatedPhoneNumber = settings.dedicatedPhoneNumber ? 
            getFormattedPhoneNumber(settings.dedicatedPhoneNumber) : defaultPhoneNumber;

        const hidePaymentSection = RequiresApproval && (!ApprovalRequiresPO || (!allowContractPoEdit && !hasNonContractItems));

        let isServiceCheckout = items.some(i => i.isServiceItem);

        return (<div id="checkout-form">
            {turnstileCompanies?.includes(rootCompanyId) && <div id="turnstile" />}
            <PageMetaData title="Checkout" />
            <div className="checkout-body">
                <h1>Checkout</h1>
                <div className="checkout-left-column">
                    <CartSummary allowEdit={false} checkoutView={true} checkoutSummaryView={!ShowCartDetails} />
                    <div className="view-details"><span onClick={this.toggleCartDetails}>{ShowCartDetails ? 'Hide Details' : 'View Details'}</span></div>
                    <div className={ShippingLocationView.Open ? 'shipping-location' : 'shipping-location closed'}>
                        <div className="checkout-section">
                            {!ShippingLocationView.Complete || ShippingLocationView.Open ? <span className="checkout-number">1</span> : null}
                            {ShippingLocationView.Complete && !ShippingLocationView.Open ? <i className="fa fa-check" /> : null}
                            <span className="checkout-label">Shipping Location</span>
                            {ShippingLocationView.Open ?
                                <div className="checkout-section-content">
                                    {settings.canAddAddress ? <div className="add-address">
                                        <span onClick={() => this.setState({ShowAddressDialog: true, AddressEdit: {country: 'US', addressTypeId: 6}, AddressFormDisplay: AddressFormType.AddEdit})}>New Address</span></div> : null}
                                    <Dropdown id="shipAddress" name="shipAddress" label={'Shipping Address'} valueField="addressId" textField="description" showErrorMessage={!shippingAddress.addressId && ShippingLocationView.ShowErrors}
                                        options={shippingAddresses} selectedValue={shippingAddress.addressId} onChange={this.handleShippingAddressChange} />
                                    <Checkbox
                                        id={`checkbox-shipping`}
                                        key={`checkbox-shipping`}
                                        className="custom-checkbox-container"
                                        label={'Include PO# on Shipping ATTN'}
                                        checked={shippingLocation.includePo}
                                        onChange={this.handleIncludePoChange} />
                                    <TextField id="shippingAttn" label="Shipping ATTN" showErrorMessage={!shippingLocation.attentionTo && ShippingLocationView.ShowErrors}
                                        placeholder="Shipping ATTN" text={shippingLocation.attentionTo} onChange={this.handleShipAttentionChange} />
                                    <ShippingAttnPreview IncludePo={shippingLocation.includePo} MaxCharacters={MaxShippingAttnLength} PoNumber={poNumber} ShipAttn={shippingLocation.attentionTo} />
                                    <Button secondary={true} onClick={() => {
                                        this.closeSection('ShippingLocation');
                                    }} loading={RetrievingShippingOptions}>Continue</Button>
                                </div> :
                                <React.Fragment>
                                    {ReadOnly ?
                                        <div className="readonly-shipping">
                                            Shipping location information unavailable.  {hasNonContractItems && 'Order shipping to multiple locations.' }
                                        </div> :
                                        <div className="checkout-section-content" style={{marginTop: '20px'}}>
                                            <div className="checkout-section-summary">
                                                <span className="checkout-section-summary-label">Shipping Address:</span>
                                                <span
                                                    className="checkout-section-summary-value">{shippingAddress.description}</span>
                                            </div>
                                            <div className="checkout-section-summary">
                                                <span className="checkout-section-summary-label">Shipping ATTN:</span>
                                                <span
                                                    className="checkout-section-summary-value">{ShippingAttentionDisplay}</span>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>}
                        </div>
                        {ShippingLocationView.Open || ReadOnly ? null : <div className="checkout-section-edit" onClick={() => {
                            this.openSection('ShippingLocation')
                        }}>Edit</div>}
                    </div>
                    <div className={ShippingMethodView.Open ? 'shipping-method' : 'shipping-method closed'} ref={this.shippingMethod}>
                        {hasContractItems && <div className="contract-ship-message">
                            <i className="fa fa-exclamation" />
                            For Contract Items, shipping methods will be determined by the Supplier.
                        </div>}

                        <ShippingStep shippingMethodView={ShippingMethodView} calculatingTaxes={CalculatingTaxes}
                            requiresApproval={RequiresApproval} hideForApprovals={hideForApprovals} onChange={this.onShippingMethodViewChange} onClose={() => {
                                this.closeSection('ShippingMethod')
                            }} retrievingShippingOptions={RetrievingShippingOptions} />
                    </div>
                    {hidePaymentSection ? null :
                        <div className={PaymentInformationView.Open ? 'payment-information' : 'payment-information closed'}
                            ref={this.paymentMethod}>
                            <div className="checkout-section">
                                {!PaymentInformationView.Complete || PaymentInformationView.Open ?
                                    <span className="checkout-number">3</span> : null}
                                {PaymentInformationView.Complete && !PaymentInformationView.Open ?
                                    <i className="fa fa-check" /> : null}
                                <span className="checkout-label">Payment</span>
                                {PaymentInformationView.Open ?
                                    <div className="checkout-section-content" style={{marginTop: '20px'}}>
                                        {(Object.keys(groupedContractItems) || []).map((x, index) => this.renderPayment(groupedContractItems[x], index))}
                                        {hasContractItems && hasNonContractItems &&
                                        <div className="contract-payment remaining">
                                            <div className="payment-amount">
                                                <span className="lbl">Payment {Object.keys(groupedContractItems).length + 1}</span>
                                                <span className="val">- Remaining Amount: {formatMoney(_.sumBy(items.filter(x => !x.isContractProOption), (item) => {
                                                    return item.quantity * item.price
                                                }))}</span>
                                            </div>
                                        </div>}
                                        {hasNonContractItems && <React.Fragment>
                                            {(facilitySettings.isLawson || facilitySettings.holdForPo || facilitySettings.submitForPo) ? null :
                                                <div>
                                                    <TextField id="poNumber" label="P.O.#"
                                                        showErrorMessage={!this.validatePONumber(paymentInformation.poNumber) && !paymentInformation.bypassPo}
                                                        placeholder="P.O.#" text={paymentInformation.poNumber}
                                                        onChange={this.handlePoNumberChange} />
                                                    {RequiresApproval && ApprovalRequiresPO && <Checkbox
                                                        id={`bypass-po`}
                                                        key={`bypass-po`}
                                                        className="custom-checkbox-container"
                                                        label={`PO to be Applied Prior to Order Submission`}
                                                        checked={paymentInformation.bypassPo}
                                                        onChange={this.handleBypassPOChange} />}
                                                    <Dropdown id="paymentMethods" name="paymentMethods" label={'Payment Method'}
                                                        onChange={this.handlePaymentMethodChange}
                                                        options={paymentMethods}
                                                        selectedValue={paymentInformation.paymentMethod.value}
                                                        showErrorMessage={!paymentInformation.paymentMethod.value && PaymentInformationView.ShowErrors} />
                                                    {paymentInformation.paymentMethod.text === 'ADD NEW CREDIT CARD' ?
                                                        <Checkbox
                                                            id={`checkbox-cc`}
                                                            key={`checkbox-cc`}
                                                            className="custom-checkbox-container"
                                                            label={`Save Credit Card`}
                                                            checked={paymentInformation.saveCC}
                                                            onChange={this.handleSaveCcChange} /> : null}
                                                </div>}
                                            <Dropdown id="billingAddresses" name="billingAddresses" label={'Billing Address'}
                                                valueField="addressId" textField="description"
                                                options={billingAddresses} selectedValue={billingAddress.addressId}
                                                onChange={this.handleBillingAddressChange}
                                                showErrorMessage={!billingAddress.addressId && PaymentInformationView.ShowErrors} />
                                        </React.Fragment>}
                                        <Button secondary={true} onClick={() => {
                                            this.closeSection('PaymentInformation');
                                        }} loading={CalculatingTaxes}>Continue</Button>
                                    </div> :
                                    <React.Fragment>
                                        {
                                            readOnlyPaymentInformation ? 
                                                <div className="readonly-shipping">
                                            Payment information unavailable. Multiple orders selected that have different PO numbers.
                                                </div> : 
                                                <div className="checkout-section-content" style={{marginTop: '20px'}}>                                                           
                                                    {(Object.keys(groupedContractItems) || []).map((x, index) => this.renderPayment(groupedContractItems[x], index))}
                                                    {hasContractItems && hasNonContractItems &&
                                            <div className="contract-payment remaining">
                                                <div className="payment-amount">
                                                    <span className="lbl">Payment {Object.keys(groupedContractItems).length + 1}</span>
                                                    <span className="val">- Remaining Amount: {formatMoney(_.sumBy(items.filter(x => !x.isContractProOption), (item) => {
                                                        return item.quantity * item.price
                                                    }))}</span>
                                                </div>
                                            </div>}
                                                    {hasNonContractItems &&
                                            <React.Fragment>
                                                {facilitySettings.isLawson || facilitySettings.holdForPo || facilitySettings.submitForPo ? null :
                                                    <div>
                                                        <div className="checkout-section-summary">
                                                            <span className="checkout-section-summary-label">P.O.#:</span>
                                                            <span
                                                                className="checkout-section-summary-value">{paymentInformation.poNumber}</span>
                                                        </div>
                                                        <div className="checkout-section-summary">
                                                            <span className="checkout-section-summary-label">Payment Method:</span>
                                                            <span
                                                                className="checkout-section-summary-value">{paymentInformation.paymentMethod.text}</span>
                                                        </div>
                                                    </div>}
                                                <div className="checkout-section-summary">
                                                    <span className="checkout-section-summary-label">Billing Address:</span>
                                                    <span
                                                        className="checkout-section-summary-value">{billingAddress.description}</span>
                                                </div>
                                            </React.Fragment>}
                                                </div>
                                   
                                        }
                                    </React.Fragment>
         
                                }
                            </div>
                            {PaymentInformationView.Open || !PaymentInformationView.Complete || (!hasNonContractItems && !allowContractPoEdit) || readOnlyPaymentInformation ? null :
                                <div className="checkout-section-edit" onClick={() => {
                                    this.openSection('PaymentInformation')
                                }}>Edit</div>}
                        </div>
                    }
                    <ShippingLabelPreview ShippingAttentionDisplay={ShippingAttentionDisplay} IsMobileOnly={true} Address={shippingAddress} />
                </div>
                <div className="checkout-right-column">
                    <PurchasePanel
                        allowPurchase={allowPurchase}
                        items={items}
                        showTotal={true}
                        onSubmit={this.submitOrder}
                        showPromotionOnTop={false}
                        facility={facility}                                                
                        paymentInfoComplete={ShippingMethodView.Complete}
                        loading={SubmittingOrder}
                        showShipping={ShippingLocationView.Complete}
                        buttonText="PLACE PURCHASE" />
                    {settings.hidePricing ? null : <PrintQuote hideTotals={!allowPurchase} />}
                    <ShippingLabelPreview ShippingAttentionDisplay={ShippingAttentionDisplay} IsDesktopOnly={true} Address={shippingAddress} />
                </div>
            </div>
            {ShowCCDialog ? <CybersourceDialog cybersourceRequest={CybersourceRequest} onClose={this.closeCCDialog} showError={ShowCybersourceError} /> : null}
            {ShowAddressDialog ? <AddressDialog onSave={this.onAddressSave} onCancel={() => this.setState({ShowAddressDialog: false})} address={AddressEdit} addressFormType={AddressFormDisplay} facilityId={facility.facilityId} /> : null}
            {showCheckoutError && 
                <Popup show={true} hideButtons={true} disableClose={true} onCancel={() => { }}>
                    {
                        this.state.checkoutErrorMessage ? 
                            <div>{this.state.checkoutErrorMessage}</div> 
                            :
                            <div>We’re sorry, there was a problem placing your order.  Please refresh and try again.  If the issue occurs, please call us at <a href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a>.</div>
                    }
                </Popup>}
            {showAlreadyCheckedoutError && 
                <Popup confirmText="Ok" cancelText="" show={true} hideButtons={false} className="note--warning cart-confirmation-popup"
                    onConfirm={() => this.alreadyCheckedOutDialogClose()}
                    onCancel={() => this.alreadyCheckedOutDialogClose()}
                    style={{padding: '25px'}}>
                    {
                        <div>Some of the items in the cart were already checked out.</div>
                    }
                </Popup>}
        </div>);
    }
}
