import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {actionCreators as cartActions} from 'stores/Cart'
import {TextField, Checkbox, Button, Dropdown, RadioButtonList, RadioButton} from '@partssourceinc/react-ui-core';
import {formatMoney} from 'utility';
import {leadTimeDescriptionCartView} from 'productUtility';
import axios from 'axios';
import PriorityOrdersModal from 'components/PriorityOrdersModal';

export default function Shipment(props) {
    const {shippingMethodView, retrievingShippingOptions, calculatingTaxes, onContinue, shipmentId, index, hideBackToCart} = props;

    const [alternateShipOptionSelected, setAlternateShipOptionSelected] = useState(false);
    const [hasPsShipOnlyItem, setHasPsShipOnlyItem] = useState(false);
    const [showPsShipOnlyItemMsg, setShowPsShipOnlyItemMsg] = useState(false);
    // const [retrievingShippingOptions, setRetrievingShippingOptions] = useState(false);
    const [tempShipAccountNo, setTempShipAccountNo] = useState('');
    const [gettingOptions, setGettingOptions] = useState(false);
    const [showAccountNoEdit, setShowAccountNoEdit] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [alertItems, setAlertItems] = useState(null);

    const dispatch = useDispatch();
    const {saveCheckout} = cartActions;
    const settings = useSelector(state => state.user.settings);
    const checkout = useSelector(state => state.cart.checkout);
    const userId = useSelector(state => state.network.tokenInfo.userId);
    const {facilitySettings, readOnlyShipping, readOnlyPaymentInformation, shipments} = checkout;
    
    const shipment = shipments.find(x => x.id === shipmentId);
    const items = checkout.items.filter(x => shipment.lineItemIds.includes(x.lineItemId));
    const {selectedShipOption, shipCarriers = [], shipOptions = [], shippingMethod} = shipment;
    const dangerousGoods = selectedShipOption?.hazmatSurcharge > 0 && !shipment.useCustomerAccount;
    const showShipWarning = showPsShipOnlyItemMsg || alternateShipOptionSelected || dangerousGoods;
    const readOnly = readOnlyShipping || readOnlyPaymentInformation;
    const freeGround = shippingMethod?.isGsa || facilitySettings.currentUserHasShippingPromo;
    const hasNonContractItems = items.some(x => !x.isContractProOption);
    const showCustomerAccountOption = !hasPsShipOnlyItem && facilitySettings.allowCustomerShippingAccount;

    let hasPsShipOnlyItemMsg, alternateShipOptionSelectedMsg, dangerousGoodsMsg;

    const isOpen = shippingMethodView.Open && shipment.open;

    if (showShipWarning) {
        if (showPsShipOnlyItemMsg)
            hasPsShipOnlyItemMsg = 'Your default shipping account cannot be used with this supplier.';
        if (alternateShipOptionSelected)
            alternateShipOptionSelectedMsg = 'Your default shipping carrier/priority selection cannot be used with this supplier.  Please select another option or use the one we have selected for you.';
        if (dangerousGoods)
            dangerousGoodsMsg = 'The shipping priority selected will be charged a ' + formatMoney(selectedShipOption.hazmatSurcharge) +
                ' surcharge due to lithium and/or dangerous goods in your purchase'
    }

    let shipAccountLabel = shippingMethod?.carrierId === 1 ? 'FedEx Account #' : 'UPS Account #';

    let isServiceCheckout = items.some(i => i.isServiceItem);

    let shipPriorities = [];

    shipOptions.filter(x => x.carrierId === shippingMethod?.carrierId).forEach(x => {

        const cost = freeGround && x.serviceLevel === 4 ? 0 : x.netShippingCharge;
        let label = x.serviceTypeDescription;

        if (!shippingMethod?.useCustomerAccount && !settings.hidePricing)
            label = label + ' <span>(' + formatMoney(cost) + ')</span>';

        shipPriorities.push({
            value: x.serviceTypeId,
            label: label,
            cost: cost,
        })
    });

    useEffect(() => {        
        if (!retrievingShippingOptions && gettingOptions) {   
            if (checkout.isApprovalOrder) {
                const psOnlyItems = items.filter(x => {
                    return x.vendorShipMethodType === 4 ||
                        x.vendorShipMethodType === 6 ||
                        x.vendorShipMethodType === 7 ||
                        x.vendorShipMethodType === 8 ||
                        x.vendorShipMethodType === 9;
                });
                
                const HasPsShipOnlyItem = psOnlyItems.length;           
                setHasPsShipOnlyItem(HasPsShipOnlyItem);              
                setGettingOptions(false);
            } else { 
                setupShippingOptions();
                setGettingOptions(false);
            }         
        }

        setGettingOptions(retrievingShippingOptions);
    }, [retrievingShippingOptions]);

    useEffect(() => {
        if (selectedShipOption?.serviceTypeId)
            setSelectedShipOption(selectedShipOption.serviceTypeId, freeGround);
    }, [shippingMethod.useCustomerAccount, shippingMethod.isGsa])

    const handlePriorityChange = (e, data) => {
        setSelectedShipOption(data.value, freeGround);
    }

    const setSelectedShipOption = (serviceTypeId, freeGroundShipping) => {
        shippingMethod.selectedPriorityId = serviceTypeId;

        if (shipOptions.length > 0)
            shipment.selectedShipOption = _.clone(shipOptions.find(x => x.serviceTypeId === Number(serviceTypeId)));

        if (freeGroundShipping && shipment.selectedShipOption.serviceLevel === 4)
            shipment.selectedShipOption.netShippingCharge = 0;
        
        dispatch(saveCheckout(checkout));
    }

    const checkPriorityOrder = async (checkout) => {
        const shipmentInfo = {
            lineItemIds: shipment.lineItemIds,
            serviceLevel: shipment.selectedShipOption ? shipment.selectedShipOption.serviceLevel : 1,
            rootCompanyId: checkout.facilitySettings.rootCompanyId,
        };

        await axios.post(`${process.env.REACT_APP_ORDER_MANAGEMENT_API}/api/PriorityOrders/checkPriorityItems`, shipmentInfo)
        .then(response => {
            let orderRisks = {};
            for (const key in response.data) {
                const item = response.data[key];
                if (item.lineItemIds.length > 0) {
                    orderRisks[key] = {
                        lineItemIds: item.lineItemIds,
                        alertMessage: item.alertMessage
                    }
                }
            }
            setAlertItems(orderRisks);
            Object.keys(orderRisks).length !== 0 ? setShowPopup(true) : onContinueShipment();
        }).catch(error => {
            // do not let an api error affect a sale conversion
            onContinueShipment();
        });
    }

    const handleCarrierChange = (e, data) => {
        const selectedCarrier = {
            carrierId: Number(data.value),
            carrier: data.options.find(x => x.value === Number(data.value)).text,
        };

        shippingMethod.selectedPriorityId = null;
        shippingMethod.carrierId = selectedCarrier.carrierId;
        dispatch(saveCheckout(checkout));

        setupShippingOptions(selectedCarrier);
    }

    const handleShipAccountChange = (e) => {
        shippingMethod.useCustomerAccount = e.checked;
        dispatch(saveCheckout(checkout));
    }

    const onEditShippingAccountNo = (e) => {
        e.preventDefault();            
        setShowAccountNoEdit(true);
        setTempShipAccountNo(shippingMethod.shipAccountNo || '');        
    }

    const onShippingAcountNoChanged = () => {
        shippingMethod.shipAccountNo = tempShipAccountNo;
        dispatch(saveCheckout(checkout));        
        setShowAccountNoEdit(false);        
        setTempShipAccountNo('');
    }

    const handleGsaChange = (e) => {
        shippingMethod.isGsa = e.checked;
        dispatch(saveCheckout(checkout));     
    }

    const handleShippingInsuranceChange = (e) => {
        shippingMethod.shippingInsurance = e.checked;
        dispatch(saveCheckout(checkout));
    }

    const setupShippingOptions = (selectedCarrier) => {        
        let AlternateShipOptionSelected = false;
        let defaultShipOption, HasPsShipOnlyItem, ShowPsShipOnlyItemMsg;

        // check for default user account
        const userSettings = settings

        if (shippingMethod?.selectedPriorityId)
            defaultShipOption = shipOptions.filter(x => x.serviceTypeId === shippingMethod.selectedPriorityId)[0];

        // GSA Checkout - Default to Ground if available
        if (facilitySettings.prePaidServiceLevelId) {
            if (!defaultShipOption && userSettings.defaultToUps && (!selectedCarrier || selectedCarrier.carrierId === 2)) {
                defaultShipOption = shipOptions.filter(x => x.serviceTypeId === 25)[0];
            }

            if (!defaultShipOption && (!selectedCarrier || selectedCarrier.carrierId === 1)) {
                defaultShipOption = shipOptions.filter(x => x.serviceTypeId === 383)[0];
            }

            if (!defaultShipOption) {
                defaultShipOption = shipOptions.filter(x => x.serviceTypeId === 25)[0];
            }
        }

        // check for user preferred option
        if (!defaultShipOption && userSettings.defaultToUps && userSettings.upsServiceTypeId && (!selectedCarrier || selectedCarrier.carrierId === 2)) {
            defaultShipOption = shipOptions.filter(x => x.serviceTypeId === userSettings.upsServiceTypeId)[0];
        }

        if (!defaultShipOption && userSettings.fedExServiceTypeId && (!selectedCarrier || selectedCarrier.carrierId === 1)) {
            defaultShipOption = shipOptions.filter(x => x.serviceTypeId === userSettings.fedExServiceTypeId)[0];
        }

        // user has a default setup and it wasn't available
        AlternateShipOptionSelected = (userSettings.upsServiceTypeId || userSettings.fedExServiceTypeId) && !defaultShipOption;

        // check for user preferred translated option (i.e. fedex ground not available, look for ups ground)
        if (!defaultShipOption && !selectedCarrier)
            defaultShipOption = shipOptions.filter(x => x.serviceTypeId === userSettings.upsServiceTypeId)[0];

        // check facility settings
        if (!defaultShipOption && facilitySettings.defaultToUps && facilitySettings.upsServiceTypeId && (!selectedCarrier || selectedCarrier.carrierId === 2)) {
            defaultShipOption = shipOptions.filter(x => x.serviceTypeId === facilitySettings.upsServiceTypeId)[0];
        }

        if (!defaultShipOption && facilitySettings.fedExServiceTypeId && (!selectedCarrier || selectedCarrier.carrierId === 1)) {
            defaultShipOption = shipOptions.filter(x => x.serviceTypeId === facilitySettings.fedExServiceTypeId)[0];
        }

        if (!defaultShipOption && facilitySettings.upsServiceTypeId && (!selectedCarrier || selectedCarrier.carrierId === 2)) {
            defaultShipOption = shipOptions.filter(x => x.serviceTypeId === facilitySettings.upsServiceTypeId)[0];
        }

        if (defaultShipOption) {
            shippingMethod.carrierId = defaultShipOption.carrierId;
            shippingMethod.carrier = defaultShipOption.carrierId === 1 ? 'FedEx' : 'UPS';
            shippingMethod.selectedPriorityId = defaultShipOption.serviceTypeId;
        } else {
            let options = selectedCarrier ? shipOptions.filter(x => x.carrierId === selectedCarrier.carrierId) : shipOptions;

            let option = options.find(x => x.serviceTypeId === 371);
            if (!option) option = options.find(x => x.serviceTypeId === 23);
            if (!option) option = options.filter(x => x.carrierId === (shipCarriers[0] || {}).value)[0];
            if (!option) option = options[0];

            defaultShipOption = option;
            shippingMethod.carrierId = selectedCarrier ? selectedCarrier.carrierId : defaultShipOption.carrierId;
            shippingMethod.carrier = selectedCarrier ? selectedCarrier.carrier : defaultShipOption.carrierId === 1 ? 'FedEx' : 'UPS';
            shippingMethod.selectedPriorityId = defaultShipOption.serviceTypeId;
        }

        // Set shipping account
        const psOnlyItems = items.filter(x => {
            return x.vendorShipMethodType === 4 ||
                x.vendorShipMethodType === 6 ||
                x.vendorShipMethodType === 7 ||
                x.vendorShipMethodType === 8 ||
                x.vendorShipMethodType === 9;
        });

        if (facilitySettings.allowCustomerShippingAccount && !psOnlyItems.length) {
            const shipVia = shippingMethod?.carrierId;

            shippingMethod.useCustomerAccount = false;
            shippingMethod.shipAccountNo = '';

            if (shipVia === 1 && facilitySettings.fedExAccountNumber) {
                shippingMethod.useCustomerAccount = true;
                shippingMethod.shipAccountNo = facilitySettings.fedExAccountNumber;
            } else if (shipVia === 2 && facilitySettings.upsAccountNumber) {
                shippingMethod.useCustomerAccount = true;
                shippingMethod.shipAccountNo = facilitySettings.upsAccountNumber;
            }
        }
        
        if (defaultShipOption) {
            let option = _.cloneDeep(defaultShipOption);
            
            if (freeGround && option.serviceLevel === 4) 
                option.netShippingCharge = 0;

            shipment.selectedShipOption = option;
        }
            
        HasPsShipOnlyItem = psOnlyItems.length;
        ShowPsShipOnlyItemMsg = HasPsShipOnlyItem && facilitySettings.allowCustomerShippingAccount;

        setAlternateShipOptionSelected(AlternateShipOptionSelected);
        setHasPsShipOnlyItem(HasPsShipOnlyItem);
        setShowPsShipOnlyItemMsg(ShowPsShipOnlyItemMsg);

        dispatch(saveCheckout(checkout));        
    }
        
    const determinePriorityOrderRisks = async () => {
        await checkPriorityOrder(checkout);
    }

    const onContinueShipment = () => {
        setShowPopup(false);
        if (!shippingMethod?.carrierId || !shippingMethod?.selectedPriorityId) {
            setShowErrors(true);
        } else if (shippingMethod?.useCustomerAccount && !shippingMethod?.shipAccountNo) {
            setShowAccountNoEdit(true);
            setShowErrors(true);
        } else {
            setShowErrors(false);
            saveShipmentDataToAudit();
            onContinue(shipment);
        }
    }

    const saveShipmentDataToAudit = () => {
        // iterate through each line item matched to that shipment upon Continue
        for (let i = 0; i < shipment.lineItemIds.length; i++) {
            let lineItemId = shipment.lineItemIds[i];
            let carrierInfo = getInitialCarrierInfo(lineItemId);

            if (carrierInfo) {
                let request = {
                    min: carrierInfo.ratingRuleMin,
                    max: carrierInfo.ratingRuleMax,
                    rateTypeId: carrierInfo.rateTypeId,
                    serviceTypeId: shippingMethod.selectedPriorityId,
                    rateValue: carrierInfo.rateValue,
                    customerPrice: carrierInfo.netShippingCharge,
                    lineItemIds: [lineItemId], // pass only this line item to lineItemIds param
                    userId: userId,
                };
        
                axios.post('/ShipIntegrationService/api/v1/rating/line/shipping-audit', request);
            }
        }
    }

    const getInitialCarrierInfo = (lineItemId) => {
        let initialCarrierInfo = null;

        // find the transaction where that line item belongs
        if (shipment && shipment.initialRateResult &&
            Array.isArray(shipment.initialRateResult.carrierServicesTransactions)) {
            let transaction = shipment.initialRateResult.carrierServicesTransactions.find(x => 
                Array.isArray(x.lineItemIds) && x.lineItemIds.includes(lineItemId));

            if (transaction && 
                Array.isArray(transaction.carrierServices)) {
                // get the first carrier with that serviceTypeId - should be only one
                initialCarrierInfo = transaction.carrierServices.find(x => x.serviceTypeId == shippingMethod.selectedPriorityId);
            }
        }

        return initialCarrierInfo;
    }

    return (
        <div className="checkout-section-shipment">
            {((settings.allowSplitShipping && !checkout.overrideSplitShipping) || checkout.shipments.length > 1) && <div className="shipment-step">{`Shipment ${index + 1}/${shipments.length}`}</div>}
            {items.map(x => (<div key={`shipment_item_${x.lineItemId}`} className="item-summary">
                ({x.quantity}) {x.partNumber} - {x.description} {x.isContractProOption && <span className="contract-badge"><span>CONTRACT</span></span>}
                <span key={index} style={{color: '#009CF4'}}>Est Ship Date: {leadTimeDescriptionCartView(x.estimatedShipDate, x.backOrderShipDate, x.shipCutoffUtc)}</span>
            </div>))}
            {isOpen && showShipWarning ?
                <div className="ship-warning">
                    {hasPsShipOnlyItemMsg ? <div><span>Note:</span>{hasPsShipOnlyItemMsg}</div> : null}
                    {alternateShipOptionSelectedMsg ? <div><span>Note:</span>{alternateShipOptionSelectedMsg}</div> : null}
                    {dangerousGoods ? <div><span>Note:</span>{dangerousGoodsMsg}</div> : null}
                </div> : null}
            {isOpen ?
                <div className="checkout-section-content" style={{marginTop: '14px'}}>
                    <Dropdown id="shipCarrier" name="shipCarrier" label={'Carrier'} onChange={handleCarrierChange} showErrorMessage={!shippingMethod?.carrierId && showErrors}
                        options={shipCarriers} selectedValue={shippingMethod?.carrierId} suppressBlankOption={true} />

                    {!showAccountNoEdit ?
                        <div>
                            {showCustomerAccountOption ? <Checkbox
                                id={`checkbox-customer-account`}
                                key={`checkbox-customer-account`}
                                className="custom-checkbox-container"
                                style={{marginTop: '10px'}}
                                checked={shippingMethod?.useCustomerAccount}
                                onChange={handleShipAccountChange}>
                                <span>
                                    {shippingMethod?.carrierId === 1 ? 'FedEx Account #:' : 'UPS Account #:'}
                                    <span className="ship-account">{shippingMethod?.shipAccountNo}</span>
                                    <span className="edit" onClick={onEditShippingAccountNo}>&nbsp;Edit</span>
                                </span>
                            </Checkbox> : null}
                        </div> :
                        <div>
                            <TextField id="txtShippingAccount" label={shipAccountLabel} showErrorMessage={!shippingMethod?.shipAccountNo && shippingMethod?.useCustomerAccount && showErrors}
                                placeholder={shipAccountLabel} text={shippingMethod?.shipAccountNo} onChange={(e) => setTempShipAccountNo(e.target.value.trim())} />
                            <Button secondary={true} onClick={onShippingAcountNoChanged}>Save</Button>
                        </div>}

                    {facilitySettings.prePaidServiceLevelId ?
                        <Checkbox
                            id={`checkbox-gsa`}
                            key={`checkbox-gsa`}
                            className="custom-checkbox-container"
                            label={'GSA Order'}
                            checked={shippingMethod?.isGsa}
                            onChange={handleGsaChange} style={{marginTop: '15px'}} /> : null}

                    <div className="ship-priorities">
                        {shipPriorities && shipPriorities.length === 1 ?
                            <RadioButton  
                                id="original-address"
                                checked={true} 
                                key={`rdPriority_${shippingMethod?.carrierId}`}                                             
                                onChange={handlePriorityChange} 
                                name="priority"
                                label={shipPriorities[0].label} />                                        
                            : <RadioButtonList name="priority" tabIndex="0" key={`rdPriority_${shippingMethod?.carrierId}`} selectedValue={shippingMethod?.selectedPriorityId} options={shipPriorities} onChange={handlePriorityChange} style={{marginTop: 0, marginBottom: 0}} /> }                                        
                    </div>
                    <Checkbox
                        id={`checkbox-insurance`}
                        key={`checkbox-insurance`}
                        className="custom-checkbox-container"
                        label={`Shipping Insurance <span class="desc">(+1% of Subtotal)</span>`}
                        checked={shippingMethod?.shippingInsurance}
                        onChange={handleShippingInsuranceChange} />
                    <Button secondary={true} onClick={() => determinePriorityOrderRisks()} loading={calculatingTaxes || retrievingShippingOptions}>Continue</Button>
                </div> :
                <React.Fragment>
                    {!hasNonContractItems ? null :
                        readOnly || isServiceCheckout ?
                            <div className="readonly-shipping">
                                {isServiceCheckout ?
                                    <span>Shipping not applied to service items.</span>
                                    :
                                    <span>Shipping method information unavailable.  Order shipping with multiple methods.</span>
                                }
                            </div> : shippingMethod?.carrierId > 0 ? 
                                <div className="checkout-section-content" style={{marginTop: '20px'}}>
                                    <div className="checkout-section-summary">
                                        <span className="checkout-section-summary-label">Carrier:</span>
                                        <span className="checkout-section-summary-value">{shippingMethod?.carrierId === 1 ? 'FedEx' : 'UPS'}</span>
                                    </div>
                                    {shippingMethod?.shipAccountNo ? <div className="checkout-section-summary">
                                        <span className="checkout-section-summary-label">Ship Account #:</span>
                                        <span className="checkout-section-summary-value">{shippingMethod?.shipAccountNo}</span>
                                    </div> : null}
                                    <div className="checkout-section-summary">
                                        <span className="checkout-section-summary-label">Shipping Method:</span>
                                        <span className="checkout-section-summary-value">{selectedShipOption?.serviceTypeDescription}</span>
                                    </div>
                                    <div className="checkout-section-summary">
                                        <span className="checkout-section-summary-label">Shipping Insurance:</span>
                                        <span className="checkout-section-summary-value">{shippingMethod?.shippingInsurance ? 'Yes' : 'No'}</span>
                                    </div>
                                    {facilitySettings.prePaidServiceLevelId ?
                                        <div className="checkout-section-summary">
                                            <span className="checkout-section-summary-label">GSA Order:</span>
                                            <span className="checkout-section-summary-value">{shippingMethod?.isGsa ? 'Yes' : 'No'}</span>
                                        </div> : null
                                    }
                                </div> : null}
                </React.Fragment>}
            {showPopup ? 
                <PriorityOrdersModal
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    onContinueShipment={onContinueShipment}
                    alertItems={alertItems}
                    fullOrder={checkout}
                    currentShipment={shipment}
                    hideBackToCart={hideBackToCart}
                /> : null
            }
        </div>
    
    );
}
