import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {actionCreators as cartActions} from 'stores/Cart'
import Shipment from './Shipment';

export default function ShippingStep(props) {
    const {shippingMethodView, retrievingShippingOptions, calculatingTaxes, requiresApproval,
        hideForApprovals, onChange} = props;
    const checkout = useSelector(state => state.cart.checkout);
    const {shipments} = checkout;
    const dispatch = useDispatch();
    const {saveCheckout} = cartActions;

    const {readOnlyShipping, readOnlyPaymentInformation, facilitySettings: {submitForPo}} = checkout;
    const readOnly = readOnlyShipping || readOnlyPaymentInformation;
    let hideBackToCart = (!requiresApproval && submitForPo) || hideForApprovals;

    const onShipmentContinue = (shipment) => {         
        let s = shipments.find(x => x.id === shipment.id);
        s.complete = true;
        s.open = false;

        let pendingShipment = shipments.find(x => !x.complete);
        if (pendingShipment != null) {
            pendingShipment.open = true;
            dispatch(saveCheckout(checkout));  
        } else {
            shippingMethodView.Open = false;
            shippingMethodView.Complete = true;
            dispatch(saveCheckout(checkout));  
            onChange(shippingMethodView);
        }
    }

    const onEdit = (shipment) => {
        let s = shipments.find(x => x.id === shipment.id);
        s.complete = true;
        s.open = true;

        shipments.filter(x => x.id !== shipment.id).forEach(x => {
            x.open = false;
        });

        shippingMethodView.Open = true;
        dispatch(saveCheckout(checkout));  
        onChange(shippingMethodView);
    }

    return (
        <div className="checkout-section">
            {(!shippingMethodView.Complete && !shippingMethodView.Open) || (shippingMethodView.Open) ? <span className="checkout-number">2</span> : null}            
            {shippingMethodView.Complete && !shippingMethodView.Open ? <i className="fa fa-check" /> : null}
            <span className="checkout-label">Shipping Method</span>
            {!readOnly && shipments.map((x,i) => 
                (<React.Fragment key={x.id} >
                    <Shipment index={i} shipmentId={x.id} shippingMethodView={shippingMethodView} calculatingTaxes={calculatingTaxes}
                        onContinue={onShipmentContinue} retrievingShippingOptions={retrievingShippingOptions} hideBackToCart={hideBackToCart} />
                    {(!shippingMethodView.Open || (shippingMethodView.Open && !x.open)) && x.complete && <div onClick={() => onEdit(x)} className="checkout-section-edit">Edit</div>}
                </React.Fragment>)
            )}     
            {readOnly && <div className="readonly-shipping">
                <span>Shipping method information unavailable.  Order shipping with multiple methods.</span>
            </div>}       
        </div>
    
    );
}
