import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Popup, Button} from '@partssourceinc/react-ui-core';
import {leadTimeDescriptionCartView} from 'productUtility';

import 'less/priorityOrderModal.less';

const PriorityOrdersModal = ({
    showPopup, setShowPopup, onContinueShipment, alertItems, fullOrder, currentShipment, hideBackToCart,
}) => {
    const history = useHistory();
    const [priorityItems, setPriorityItems] = useState({});
    const [alertTriggerCount, setAlertTriggerCount] = useState(0);

    useEffect(() => {
        if (showPopup) {
            const items = getPriorityItemsPerShipment();
            existingPriorityItems(items);
        }
    }, [showPopup, fullOrder]);

    const getPriorityItemsPerShipment = () => {
        let items = {};

        currentShipment.lineItemIds.forEach(lineItemId => {
            const matchingItems = fullOrder.items.filter(item => item.lineItemId === lineItemId);
            matchingItems.forEach(matchingItem => {
                Object.keys(alertItems).forEach(key => {
                    if (alertItems[key].lineItemIds && alertItems[key].lineItemIds.includes(lineItemId)) {
                        alertItems[key].descriptions = alertItems[key].descriptions || [];
                        alertItems[key].estimatedShipDates = alertItems[key].estimatedShipDates || [];
                        alertItems[key].backOrderShipDates = alertItems[key].backOrderShipDates || [];
                        alertItems[key].shipCutoffTimes = alertItems[key].shipCutoffTimes || [];
                        updateAlertItemDetails(alertItems[key], matchingItem);
                        items[key] = alertItems[key];
                    }
                });
            });
        });

        setPriorityItems(items);
        return items;
    };

    const updateAlertItemDetails = (alertItem, matchingItem) => {
        const combinedDescription = `${matchingItem.partNumber} ${matchingItem.description}`;
        if (!alertItem.descriptions.includes(combinedDescription)) {
            alertItem.descriptions.push(combinedDescription);
            alertItem.estimatedShipDates.push(matchingItem.estimatedShipDate);
            alertItem.backOrderShipDates.push(matchingItem.backOrderShipDate);
            alertItem.shipCutoffTimes.push(matchingItem.shipCutoffUtc);
        }
    };

    const existingPriorityItems = (items) => {
        if (Object.keys(items).length === 0) {
            setShowPopup(false);
            onContinueShipment();
        } else {
            setAlertTriggerCount(Object.keys(items).length);
        }
    };

    const onPopupCancel = () => {
        setShowPopup(false);
    };

    return (
        <React.Fragment>
            <Popup
                className="widePopup"
                show={showPopup}
                onCancel={onPopupCancel}
                hideButtons={true}
            >
                <div className="priority-shipping-header" style={{display: 'flex', justifyContent: 'center', paddingBottom: '25px'}}>
                    <h3>Priority Shipping Acknowledgement</h3>
                </div>
                <div>
                    {Object.keys(priorityItems).map((key) => (
                        <div key={key}>
                            <p style={{overflowWrap: 'break-word'}} dangerouslySetInnerHTML={{__html: priorityItems[key].alertMessage}} />
                            <p style={{borderBottom: alertTriggerCount === 0 ? 
                                '1px solid black' : '1px solid lightgray', paddingBottom: '20px', marginBottom: '20px'}}>
                                {priorityItems[key].descriptions.map((description, index) => (
                                    <span key={index}>{description}&nbsp;&nbsp;
                                        {priorityItems[key].estimatedShipDates[index] && (
                                            <span style={{color: '#009CF4'}}>
                                                Est Ship Date: {leadTimeDescriptionCartView(
                                                    priorityItems[key].estimatedShipDates[index],
                                                    priorityItems[key].backOrderShipDates[index],
                                                    priorityItems[key].shipCutoffTimes[index])
                                                }
                                            </span>
                                        )}
                                        <br />
                                    </span>
                                ))}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="priority-shipping-footer" style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {!hideBackToCart && (
                        <Button ternary={true} onClick={() => history.push('/cart')}>
                            Back to Cart
                        </Button>
                    )}
                    <Button ternary={true} onClick={onPopupCancel}>
                        Change Shipping
                    </Button>
                    <Button onClick={onContinueShipment}>
                        Acknowledge
                    </Button>
                </div>
            </Popup>
        </React.Fragment>
    );
}

export default PriorityOrdersModal;
