import * as PropTypes from 'prop-types';
import * as React from 'react';

export default class ShippingLabelPreview extends React.Component {
    static propTypes = {
        ShippingAttentionDisplay: PropTypes.string,
        IsMobileOnly: PropTypes.bool,
        IsDesktopOnly: PropTypes.bool,
        Address: PropTypes.shape({
            street1: PropTypes.string,
            street2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            postalCode: PropTypes.string,
            country: PropTypes.string,
        }),
    };

    render() {
        const {ShippingAttentionDisplay, IsMobileOnly, IsDesktopOnly, Address} = this.props;
        const cssClass = IsMobileOnly 
            ? 'inline-shipping-preview mobile-only' 
            : IsDesktopOnly
                ? 'inline-shipping-preview desktop-only'
                : 'inline-shipping-preview';

        return (
            <div className={cssClass}>
                <div className="shipping-label-preview">Shipping Label Preview</div>
                <div className="shipping-label-preview-panel">
                    <div>SHIP TO:</div>
                    <span>{ShippingAttentionDisplay}</span>
                    {Address.street1 && <React.Fragment>
                        <span>{Address.street1}</span>
                        {Address.street2 && <span>{Address.street2}</span>}
                        <span>{Address.city}, {Address.state} {Address.postalCode}</span>
                        <span>{Address.country}</span>
                    </React.Fragment>}
                </div>
            </div>
        );
    }
}
