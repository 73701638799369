import * as React from 'react';
import {connect} from 'react-redux';

import SalesQuote from 'components/SalesQuote';
import * as CartStore from 'stores/Cart';

@connect((state) => ({cart: state.cart}), CartStore.actionCreators)
export default class PrintQuote extends React.Component {
    static propTypes = {
        cart: CartStore.StateShape,
        ...CartStore.ActionShape,
    };
    
    constructor(props) {
        super(props);

        this.state = {
            showQuote: false,
        }
    }

    showQuote() {
        const {disable, resetCheckout, saveCheckout, isCart} = this.props;

        if (disable) return;

        if (isCart) {
            // load specified facility into checkout
            const {cart} = this.props;
            const checkout = {
                items: cart.cartItems.filter(x => x.cartItemId === cart.selectedCartItemId),
                facility: cart.uniqueFacilities.find(x => x.cartItemId === cart.selectedCartItemId),
            };
            resetCheckout();
            saveCheckout(_.merge({}, cart.checkout, checkout));
        }

        this.setState({showQuote: true});
    }

    render() {
        const {showQuote} = this.state;
        const {disable, hideTotals} = this.props;

        const style = (disable && {opacity: .5}) || {};
        const title = disable ? 'No Facility Selected' : '';

        return (
            <div className="print-quote-panel">
                <h2>Print Quote</h2>
                <div className="quote-instructions">
                    To obtain a fully burdened quote, please proceed to checkout and enter your shipping information.
                </div>
                <div className="quote-buttons">
                    <span onClick={::this.showQuote} style={style} title={title}>PRINT</span>
                </div>
                {showQuote && <SalesQuote hideTotals={hideTotals} onClose={() => this.setState({showQuote: false})} />}
            </div>
        );
    }
}
