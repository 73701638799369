import * as React from 'react';
import {Button} from '@partssourceinc/react-ui-core';
import {formatMoney} from 'utility';
import {connect} from 'react-redux';
import PromotionDialog from 'components/PromotionDialog';
import * as CartStore from 'stores/Cart';
import * as UserStore from 'stores/User';
import axios from 'axios';

@connect((state) => ({checkout: state.cart.checkout, user: state.user, network: state.network}), CartStore.actionCreators)
export default class PurchasePanel extends React.Component {
    static propTypes = {
        ...CartStore.ActionShape,
        user: UserStore.StateShape,
        checkout: CartStore.StateShape.checkout,
    };
    
    constructor(props) {
        super(props);
    
        this.state = {
            showPromotionDialog: false,
            calculatingTotals: false,
        }
    }
    
    onRemovePromo() {
        let {checkout, saveCheckout, getOrderTotals} = this.props;
    
        let lineItemIds = checkout.items.map(x => x.lineItemId);
        this.setState({calculatingTotals: true});
        axios.post('/ShoppingService/api/v1/cart/remove/promo', {lineItemIds}).then(x => {
            const {data: {lineItems}} = x;
            checkout.promoCode = '';
            checkout.items.forEach(x => {
                let ni = lineItems.find(li => li.lineItemId === x.lineItemId);
                x.price = ni.price;
                x.promotionDiscount = ni.promotionDiscount;
                x.originalPromotionPrice = ni.originalPromotionPrice;
            });
            saveCheckout(checkout);
    
            getOrderTotals(true).then(() => {
                this.setState({calculatingTotals: false});
            })
        })
    }
    
    onPromoApplied() {
        const {getOrderTotals} = this.props;
        this.setState({showPromotionDialog: false, calculatingTotals: true});
    
        getOrderTotals(true).then(() => {
            this.setState({calculatingTotals: false});
        });
    }
    
    render() {
        const {
            allowPurchase,
            creditHold,
            onSubmit,
            showTotal,
            hideButtons,
            items,
            showPromotionOnTop,
            buttonText,
            facility,            
            includeInsurance,
            loading,
            paymentInfoComplete,
            showShipping,
            user: {settings: {hidePricing}},
            network: {tokenInfo: {subscriptionId}},
            checkout: {
                totals: {taxes,creditCardFees, processingFees, minOrderFees, exchangeFees, programFees, oemFees, shipping, transitCoverages, lineItemFees, initialProcessingFee},
                paymentInformation: {paymentMethod},
                facilitySettings: {purchaseLimit, submitForPo, isLawson, removePromotion, bypassFormularyApproval},
                promoCode,promoDisplay,isApprovalOrder,canCheckout,readOnlyShipping,
                shipments,
            }} = this.props;

        const hidePromotion = removePromotion || isApprovalOrder;

        const {showPromotionDialog, calculatingTotals} = this.state;

        const subTot = (items || []).filter(x => x.quantity).reduce(function (res, item) {
            return res + (item.price * item.quantity);
        }, 0);

        const eligibleItemsSubTot = (items || []).filter(x => x.quantity && !x.isContractProOption).reduce(function (res, item) {
            return res + (item.price * item.quantity);
        }, 0);

        const contractItemsSubTot = (items || []).filter(x => x.quantity && x.isContractProOption).reduce(function (res, item) {
            return res + (item.price * item.quantity);
        }, 0);

        const invalidQty = items.filter(x => !x.quantity || (x.moq && x.quantity < x.moq)).length > 0;

        const subTotal = formatMoney(subTot);    
        const itemRequiresApproval = items.some(x => !x.isFormularyOption && x.nonFormularyApprovalRequired && ((x.price * x.quantity) > x.nonFormularyApprovalLimit)) && facility.purchaseLimit !== 999999;    
        const needsApproval = (subTot > purchaseLimit) || (itemRequiresApproval && !isApprovalOrder && canCheckout && !bypassFormularyApproval);

        let shippingCostTotal = 0.00;
        
        shipments.forEach(x => {
            const {shippingMethod: {useCustomerAccount, shippingInsurance}, selectedShipOption: {netShippingCharge}} = x;

            const itemsSubTot = (items || []).filter(i => i.quantity && !i.isContractProOption && x.lineItemIds.includes(i.lineItemId)).reduce(function (res, item) {
                return res + (item.price * item.quantity);
            }, 0);
            const shippingInsuranceCost = shippingInsurance ? itemsSubTot * .01 : 0;

            if (!useCustomerAccount)
                shippingCostTotal += (netShippingCharge + shippingInsuranceCost);
            else 
                shippingCostTotal += shippingInsuranceCost;          
        });

        if (shipments.some(x => x.shippingMethod.useCustomerAccount))
            shippingCostTotal += isApprovalOrder ? processingFees : initialProcessingFee;
                        
        if (isNaN(shippingCostTotal)) shippingCostTotal = 0;
        let shippingCostDisplay = formatMoney(shippingCostTotal);

        let total = subTot + shippingCostTotal + minOrderFees + exchangeFees + programFees + oemFees;

        let showCCFees = false;
        if (paymentInfoComplete && (paymentMethod.value && paymentMethod.value !== 'PO') && creditCardFees) {
            showCCFees = true;
            total += creditCardFees;
        }

        if (allowPurchase)
            total += taxes;

        total = formatMoney(total);

        let displayButtonText = buttonText;

        if (isLawson && subscriptionId.toString() === 'false') displayButtonText = 'Submit for Punchout';

        const hasContractItems = items.some(x => x.isContractProOption);

        if (submitForPo && !items.every(x => x.isContractProOption)) displayButtonText = 'Submit for PO';
        if (needsApproval) displayButtonText = 'Submit for Approval';

        return (
            <div>
                {showPromotionOnTop && !hidePromotion ? <div className="purchase-panel standalone mobile-only">
                    <div className="purchase-panel-promotion-wrapper">
                        <span className="purchase-panel-promotion" onClick={() => this.setState({showPromotionDialog: true})}>Add Promotional Code</span>
                    </div>
                </div> : null}
                <div className="purchase-panel">
                    {hideButtons ? null :
                        <div className={showPromotionOnTop ? 'desktop-only' : ''}>
                            {allowPurchase && !invalidQty && !calculatingTotals ? <Button onClick={onSubmit} loading={loading} disabled={creditHold}>{displayButtonText}</Button> : <Button disabled={true}>{displayButtonText}</Button> }
                        </div> }
                    {facility ?
                        <div className="purchase-panel-facility">
                            <i className="fa fa-map-marker" />
                            <span>{facility.facilityName}</span>
                        </div> :
                        <div className="purchase-panel-facility">
                            <i className="fa fa-map-marker" />
                            <span>No Facility Selected</span>
                        </div>
                    }
                    {showTotal && !hidePricing ?
                        <div className="purchase-panel-prices">
                            {contractItemsSubTot > 0 && eligibleItemsSubTot > 0 ?
                                <React.Fragment>
                                    <div className="purchase-panel-price">
                                Contract Subtotal: <span className="price">{formatMoney(contractItemsSubTot)}</span>
                                    </div>
                                    <div className="purchase-panel-price">
                                        Non-Contract Subtotal: <span className="price">{formatMoney(eligibleItemsSubTot)}</span>
                                    </div>
                                </React.Fragment> :
                                <div className="purchase-panel-price">
                                    Subtotal: <span className="price">{subTotal}</span>
                                </div>
                            }
                            {programFees ?
                                <div className="purchase-panel-price">
                  Program Fee: <span className="price">{formatMoney(programFees)}</span>
                                </div> : null}
                            {exchangeFees ?
                                <div className="purchase-panel-price">
                  Exchange Fee: <span className="price">{formatMoney(exchangeFees)}</span>
                                </div> : null}
                            {oemFees ?
                                <div className="purchase-panel-price">
                  Manufacturer Fee: <span className="price">{formatMoney(oemFees)}</span>
                                </div> : null}
                            {minOrderFees ?
                                <div className="purchase-panel-price">
                  Minimum Order Fee: <span className="price">{formatMoney(minOrderFees)}</span>
                                </div> : null}
                            {showShipping ?
                                <div className="purchase-panel-price">
                  Shipping/Processing: <span className="price">{shippingCostDisplay}</span>
                                </div> : null}
                            {showCCFees ?
                                <div className="purchase-panel-price">
                  Credit Card Fee: <span className="price">{formatMoney(creditCardFees)}</span>
                                </div> : null}
                            {allowPurchase ?
                                <div className="purchase-panel-price">
                  Tax: <span className="price">{formatMoney(taxes)}</span>
                                </div> : null}
                            <div className="purchase-panel-total">
                Total: <span className="price" id="checkout-grand-total">{total}</span>
                            </div>
                        </div> :
                        <div className="purchase-panel-prices">
                            {!hidePricing ?
                                <div className="purchase-panel-total">
                  Subtotal: <span className="price">{formatMoney(subTotal)}</span>
                                </div> : null}
                        </div>}
                    {hideButtons || !showPromotionOnTop ? null :
                        <div className="mobile-only" style={{marginTop: '20px'}}>
                            {allowPurchase && !calculatingTotals ? <Button onClick={onSubmit} loading={loading} disabled={creditHold}>{displayButtonText}</Button> : <Button disabled={true}>{displayButtonText}</Button> }
                        </div>}
                    {showShipping && hasContractItems && <div className="purchase-panel-contract-message">
                        <div className="contract-badge"><span>CONTRACT</span></div>
                        All on-contract purchases will be billed by the supplier inclusive of Shipping/Processing Fees and Taxes.
                    </div>}
                    {hideButtons || !showTotal || hidePromotion ? null :
                        <div className="purchase-panel-promotion-wrapper">
                            {
                                calculatingTotals ? <div><div className="loader-wrapper"><div className="loader" /></div><div className="loader-msg">Calculating Totals</div></div> :
                                    <React.Fragment>
                                        {promoCode ? <span className="purchase-panel-promotion promo">
                                            <span>{promoDisplay}</span><span className="remove" onClick={::this.onRemovePromo}>Remove</span>
                                        </span> :
                                            <span className="purchase-panel-promotion" onClick={() => this.setState({showPromotionDialog: true})}>Add Promotional Code</span>
                                        }
                                    </React.Fragment>
                            }
                        </div>}
                </div>
                {showPromotionDialog ? <PromotionDialog onApply={::this.onPromoApplied} onCancel={() => this.setState({showPromotionDialog: false})} /> : null}
            </div>
        );
    }
}
