import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

export default class ShippingAttnPreview extends Component {
    static propTypes = {
        IncludePo: PropTypes.bool,
        PoNumber: PropTypes.string,
        ShipAttn: PropTypes.string,
        MaxCharacters: PropTypes.number,
    };

    constructor(props) {
        super(props);
    }

    render () {
        const {IncludePo, PoNumber, ShipAttn, MaxCharacters} = this.props;
        const ShippingAttentionDisplay = ShipAttn 
            ? (IncludePo ? 'PO#:' + PoNumber + ', ' + ShipAttn : ShipAttn).substring(0, 35) 
            : (IncludePo ? 'PO#:' + PoNumber : '').substring(0, 35);
      
        const PoNumberLength = IncludePo ? 6 + PoNumber.length : 0;

        const dots = [];
        _.times(MaxCharacters - ShippingAttentionDisplay.length, () => {
            dots.push('-');
        });

        return (<div style={{color: '#949494', fontSize: '12px', marginTop: '5px'}}>
            {IncludePo ?
                <span>
                    <span>PO#:{PoNumber}</span>
                    {dots.length && <span style={{letterSpacing: '5px', paddingLeft: '5px'}}>{dots}</span>}
                    {ShipAttn ? <span>, </span> : null}
                    <span>{ShipAttn ? ShipAttn.substring(0, 35 - PoNumberLength) : null}</span>
                    <span style={{float: 'right'}}>{ShippingAttentionDisplay.length}/{MaxCharacters}</span>
                </span> :
                <span>
                    <span>{ShipAttn ? ShipAttn.substring(0, 35 - PoNumberLength) : null}</span>
                    {dots.length && <span style={{letterSpacing: '5px', paddingLeft: '5px'}}>{dots}</span>}
                    <span style={{float: 'right'}}>{ShippingAttentionDisplay.length}/{MaxCharacters}</span>
                </span>
            }
        </div>);
    }
}

