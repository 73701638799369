import axios from 'axios';
import PropTypes from 'prop-types'
import * as React from 'react';
import {connect} from 'react-redux';
import {TextField, Popup} from '@partssourceinc/react-ui-core';
import * as CartStore from 'stores/Cart';

@connect((state) => ({checkout: state.cart.checkout}), CartStore.actionCreators)
export default class PromotionDialog extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onApply: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        checkout: CartStore.CheckoutShape,
        ...CartStore.ActionShape,
    };

    constructor(props) {
        super(props);
        this.state = {
            promoCode: '',
            showErrors: false,
            loading: false,
            errorMessage: '',
        };
    }

    onApply() {
        let {checkout, saveCheckout, onApply} = this.props;
        const {promoCode} = this.state;

        if (!promoCode) {
            this.setState({showErrors: true});
            return;
        }

        this.setState({loading: true});
        let lineItemIds = checkout.items.map(x => x.lineItemId);
        axios.post('/ShoppingService/api/v1/cart/add/promo', {lineItemIds, promoCode}).then(x => {
            const {data: {isValid, reason, lineItems, display}} = x;

            if (isValid) {
                checkout.promoCode = promoCode.toUpperCase();
                checkout.promoDisplay = display;
                checkout.items.forEach(x => {
                    let ni = lineItems.find(li => li.lineItemId === x.lineItemId);
                    x.price = ni.price;
                    x.promotionDiscount = ni.promotionDiscount;
                    x.originalPromotionPrice = ni.originalPromotionPrice;
                });
                saveCheckout(checkout);
                this.setState({loading: false});
                onApply();
            } else {
                this.setState({errorMessage: reason, loading: false});
            }
        })
    }

    render() {
        const {promoCode, showErrors, loading, errorMessage} = this.state;
        const {onCancel} = this.props;

        return (
            <Popup confirmText="Apply"
                cancelText="Cancel"
                show={true}
                hideButtons={false}
                onCancel={onCancel}
                onConfirm={::this.onApply}
                loading={loading}
            >
                <div>
                    <h1 style={{marginTop: '0'}}>Enter Promotion Code</h1>
                    {errorMessage ? <div style={{color: '#FF0000', fontWeight: '600'}}>{errorMessage}</div> : null}
                    <TextField label="Promo Code" showErrorMessage={showErrors && !promoCode}
                        placeholder="Promo Code" text={promoCode}
                        onChange={(e) => this.setState({promoCode: e.target.value})} />
                </div>
            </Popup>
        );
    }
}
