import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Popup} from '@partssourceinc/react-ui-core';
import $ from 'jquery';
import 'less/cybersource.less';

export default class CybersourceDialog extends Component {
    static propTypes = {
        cybersourceRequest: PropTypes.shape({
            transaction_type: PropTypes.string,
            allow_payment_token_update: PropTypes.string,
            access_key: PropTypes.string,
            profile_id: PropTypes.string,
            reference_number: PropTypes.string,
            payment_token: PropTypes.string,
            override_custom_cancel_page: PropTypes.string,
            override_custom_receipt_page: PropTypes.string,
            merchant_defined_data6: PropTypes.string,
            transaction_uuid: PropTypes.string,
            signed_date_time: PropTypes.string,
            signed_field_names: PropTypes.string,
            unsigned_field_names: PropTypes.string,
            bill_to_address_country: PropTypes.string,
            bill_to_email: PropTypes.string,
            card_expiry_date: PropTypes.string,
            ship_to_address_city: PropTypes.string,
            ship_to_address_country: PropTypes.string,
            ship_to_address_line1: PropTypes.string,
            ship_to_address_line2: PropTypes.string,
            ship_to_address_postal_code: PropTypes.string,
            ship_to_address_state: PropTypes.string,
            merchant_defined_data5: PropTypes.string,
            merchant_defined_data7: PropTypes.string,
        }).isRequired,
        showError: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    componentDidMount() {
        $('#PaymentForm').submit();
    }

    render() {
        const {cybersourceRequest, showError, onClose} = this.props;

        return (
            <Popup
                show={true}
                onCancel={onClose}
                hideButtons={true}>
                {showError ? <div>There was a problem submitting payment information.</div> :
                    <div>
                        <form action={process.env.REACT_APP_CYBERSOURCE_URL + '/pay'}
                            id="PaymentForm" target="CardDetails" method="post"
                        >
                            {cybersourceRequest.payment_token ? <span>
                                <input type="hidden" name="access_key" value={cybersourceRequest.access_key} />
                                <input type="hidden" name="profile_id" value={cybersourceRequest.profile_id} />
                                <input type="hidden" name="reference_number" value={cybersourceRequest.reference_number} />
                                <input type="hidden" name="payment_token" value={cybersourceRequest.payment_token} />
                                <input type="hidden" name="transaction_type" value={cybersourceRequest.transaction_type} />
                                <input type="hidden" name="amount" value={parseFloat(cybersourceRequest.amount).toFixed(2)} />
                                <input type="hidden" name="currency" value={cybersourceRequest.currency} />
                                <input type="hidden" name="locale" value={cybersourceRequest.locale} />
                                <input type="hidden" name="override_custom_cancel_page" value={cybersourceRequest.override_custom_cancel_page} />
                                <input type="hidden" name="override_custom_receipt_page" value={cybersourceRequest.override_custom_receipt_page} />
                                <input type="hidden" name="transaction_uuid" value={cybersourceRequest.transaction_uuid} />
                                <input type="hidden" name="signed_date_time" value={cybersourceRequest.signed_date_time} />
                                <input type="hidden" name="signed_field_names" value={cybersourceRequest.signed_field_names} />
                                <input type="hidden" name="unsigned_field_names" value={cybersourceRequest.unsigned_field_names} />
                                <input type="hidden" name="ship_to_address_city" value={cybersourceRequest.ship_to_address_city} />
                                <input type="hidden" name="ship_to_address_country" value={cybersourceRequest.ship_to_address_country} />
                                <input type="hidden" name="ship_to_address_line1" value={cybersourceRequest.ship_to_address_line1} />
                                <input type="hidden" name="ship_to_address_line2" value={cybersourceRequest.ship_to_address_line2} />
                                <input type="hidden" name="ship_to_address_postal_code" value={cybersourceRequest.ship_to_address_postal_code} />
                                <input type="hidden" name="ship_to_address_state" value={cybersourceRequest.ship_to_address_state} />
                                <input type="hidden" name="merchant_defined_data5" value={cybersourceRequest.merchant_defined_data5} />
                                <input type="hidden" name="merchant_defined_data6" value={cybersourceRequest.merchant_defined_data6} />
                                <input type="hidden" name="merchant_defined_data7" value={cybersourceRequest.merchant_defined_data7} />
                                <input type="hidden" name="signature" value={cybersourceRequest.signature} />
                            </span> : <span>
                                <input type="hidden" name="access_key" value={cybersourceRequest.access_key} />
                                <input type="hidden" name="profile_id" value={cybersourceRequest.profile_id} />
                                <input type="hidden" name="reference_number" value={cybersourceRequest.reference_number} />
                                <input type="hidden" name="transaction_type" value={cybersourceRequest.transaction_type} />
                                <input type="hidden" name="amount" value={parseFloat(cybersourceRequest.amount).toFixed(2)} />
                                <input type="hidden" name="currency" value={cybersourceRequest.currency} />
                                <input type="hidden" name="locale" value={cybersourceRequest.locale} />
                                <input type="hidden" name="bill_to_address_country" value={cybersourceRequest.bill_to_address_country} />
                                <input type="hidden" name="bill_to_email" value={cybersourceRequest.bill_to_email} />
                                <input type="hidden" name="card_expiry_date" value={cybersourceRequest.card_expiry_date} />
                                <input type="hidden" name="override_custom_cancel_page" value={cybersourceRequest.override_custom_cancel_page} />
                                <input type="hidden" name="override_custom_receipt_page" value={cybersourceRequest.override_custom_receipt_page} />
                                <input type="hidden" name="transaction_uuid" value={cybersourceRequest.transaction_uuid} />
                                <input type="hidden" name="signed_date_time" value={cybersourceRequest.signed_date_time} />
                                <input type="hidden" name="signed_field_names" value={cybersourceRequest.signed_field_names} />
                                <input type="hidden" name="unsigned_field_names" value={cybersourceRequest.unsigned_field_names} />
                                <input type="hidden" name="ship_to_address_city" value={cybersourceRequest.ship_to_address_city} />
                                <input type="hidden" name="ship_to_address_country" value={cybersourceRequest.ship_to_address_country} />
                                <input type="hidden" name="ship_to_address_line1" value={cybersourceRequest.ship_to_address_line1} />
                                <input type="hidden" name="ship_to_address_line2" value={cybersourceRequest.ship_to_address_line2} />
                                <input type="hidden" name="ship_to_address_postal_code" value={cybersourceRequest.ship_to_address_postal_code} />
                                <input type="hidden" name="ship_to_address_state" value={cybersourceRequest.ship_to_address_state} />
                                <input type="hidden" name="merchant_defined_data5" value={cybersourceRequest.merchant_defined_data5} />
                                <input type="hidden" name="merchant_defined_data6" value={cybersourceRequest.merchant_defined_data6} />
                                <input type="hidden" name="merchant_defined_data7" value={cybersourceRequest.merchant_defined_data7} />
                                <input type="hidden" name="signature" value={cybersourceRequest.signature} />
                            </span>}
                        </form>
                        <iframe name="CardDetails" id="CardDetails" />
                    </div>}
            </Popup>);
    }
}
